import moment from "moment";
import { errorSwal } from "./swal";
import { Product, Invoice } from "./types";

// export const saltyCategories = ["EPICERIE SALE"];
// export const sweetCategories = ["EPICERIE SUCREE"];
// export const fruitCategories = ["FRUITS"];
// export const beverageCategories = [
//   "BOISSONS CHOCOLATEES",
//   "BOISSONS SUCREES",
//   "CHAMPAGNES, VINS, CREME",
//   "LAIT",
//   "THE GLACE",
// ];
// export const juiceCategories = ["JUS", "MACHINE A JUS"];
// export const sodaCategories = ["SODAS"];
// export const waterCategories = ["EAUX"];
// export const coffeCategories = [
//   "CAFE",
//   "CAFE EN CAPSULE ET DOSETTE",
//   "CAFE EN PAQUET",
//   "CAFE SOLUBLE",
//   "CAFES",
//   "MACHINE À CAFÉ",
//   "CONSOMMATION CAFE",
// ];
// export const teaCategories = [
//   "BOISSONS AU THE",
//   "INFUSION ET TISANE EN SACHET",
//   "INFUSION ET TISANE EN VRAC",
//   "INFUSIONS",
//   "THE EN CAPSULE ET DOSETTE",
//   "THE EN COFFRET",
//   "THE EN SACHET",
//   "THE EN VRAC",
//   "THES",
// ];
// export const guestCategories = ["COHESION D'EQUIPE"];

// export const hygieneCategories = [
//   "Essuyage et papier hygiénique",
//   "HYGIENE DE LA CUISINE",
//   "Hygiène de la personne",
//   "HYGIÈNE DE LA PERSONNE",
//   "HYGIÈNE SANITAIRE",
//   "Infirmerie",
//   "MOUCHOIRS ET DISTRIBUTEURS",
//   "NETTOYANT MÉNAGER",
//   "Produit d'entretien et d'hygiene",
//   "MATERIEL NETTOYAGE",
//   "Matériel d'entretien",
// ];

// export const furnitureCategories = [
//   "Mobilier",
//   "MOBILIER",
//   "PLANTES",
//   "PLANTES ET ENTRETIEN",
//   "Plantes/Fleurs",
// ];

// export const fountainCategories = ["FONTAINE", "FONTAINE A EAU"];

// export const shippingCategories = [""];

// export const filterAllCategories = [
//   ...guestCategories,
//   ...saltyCategories,
//   ...sweetCategories,
//   ...fruitCategories,
// ];
// export const filterAllSubCategories = [
//   ...beverageCategories,
//   ...juiceCategories,
//   ...sodaCategories,
//   ...waterCategories,
//   ...coffeCategories,
//   ...teaCategories,
//   ...hygieneCategories,
//   ...furnitureCategories,
// ];

// export const displayAllCategories = [
//   ...saltyCategories,
//   ...sweetCategories,
//   ...fruitCategories,
// ];

// export const displayAllSubCategories = [
//   ...beverageCategories,
//   ...coffeCategories,
//   ...teaCategories,
//   ...hygieneCategories,
// ];

// export const tableAllCategories = [
//   ...saltyCategories,
//   ...sweetCategories,
//   ...fruitCategories,
// ];

// export const tableAllSubCategories = [
//   ...beverageCategories,
//   ...coffeCategories,
//   ...fountainCategories,
//   ...teaCategories,
//   ...shippingCategories,
// ];

// export const getFilters = (category: string | undefined) => {
//   return (product: Product) => {
//     if (!category) {
//       return true;
//     }
//     if (category === "salty") {
//       return saltyCategories.includes(product.category);
//     }
//     if (category === "sweet") {
//       return sweetCategories.includes(product.category);
//     }

//     if (category === "fruits") {
//       return fruitCategories.includes(product.category);
//     }

//     if (category === "beverage") {
//       return beverageCategories.includes(product.subCategory);
//     }

//     if (category === "juice") {
//       return juiceCategories.includes(product.subCategory);
//     }

//     if (category === "sodas") {
//       return sodaCategories.includes(product.subCategory);
//     }

//     if (category === "water") {
//       return waterCategories.includes(product.subCategory);
//     }

//     if (category === "coffe") {
//       return coffeCategories.includes(product.subCategory);
//     }

//     if (category === "tea") {
//       return teaCategories.includes(product.subCategory);
//     }

//     if (category === "guest") {
//       return guestCategories.includes(product.category);
//     }

//     if (category === "cleaning") {
//       return hygieneCategories.includes(product.subCategory);
//     }

//     if (category === "furnitures") {
//       return furnitureCategories.includes(product.subCategory);
//     }

//     if (category === "other") {
//       return (
//         !filterAllCategories.includes(product.category) &&
//         !filterAllSubCategories.includes(product.subCategory)
//       );
//     }
//   };
// };

export const formatNumber = (val: number | undefined) =>
  val ? Number(Number(val).toFixed(2)) : 0;
export const roundNumber = (val: number) => (val ? Number(val.toFixed(0)) : 0);
export const displayNumber = (number: number): string => {
  return parseFloat(String(number)).toFixed(2);
};

export const displayVal = (val: any) => {
  if (isNaN(val)) {
    return val;
  }
  return displayNumber(val);
};

export const divideNumbers = (
  numerator: number,
  denominator: number | undefined
): number => {
  if (denominator === undefined || denominator === 0) {
    return 0; // or any other suitable value you prefer when the denominator is zero
  }

  return numerator / denominator;
};

export const productPrice = (product: Product) =>
  product?.prices
    ?.filter((pricing) => pricing.quantityMin <= product.quantity)
    .sort((a, b) => b.quantityMin - a.quantityMin)[0]?.price;

export const getPriceWithDiscount = ({
  price,
  discount,
}: {
  price: number;
  discount: number;
}) => {
  if (!discount || discount === 0) {
    return price;
  }

  if (!price) {
    return 0;
  }

  return price - price * (discount / 100);
};

export const calculateSellingPrice = ({
  marking,
  purchasePrice,
}: {
  marking: number;
  purchasePrice: number;
}): number => {
  if (marking === 0) {
    return purchasePrice;
  }
  if (marking >= 100 || marking < 0) {
    return 0;
  }
  return (purchasePrice / (100 - marking)) * 100;
};

export const getMargin = ({
  purchasePrice,
  price,
}: {
  purchasePrice: number;
  price: number;
}) => {
  if (price === 0) {
    return 0;
  }
  return divideNumbers(price - purchasePrice, price) * 100;
};

export const getValueLabel = (key: string) => {
  let label = "";
  if (["margin", "discount"].includes(key)) {
    label = "%";
  } else if (
    ["currentUnitPrice", "currentPackPrice", "finalPrice"].includes(key)
  ) {
    label = "€";
  }
  return label;
};

export const updateProduct = ({
  p,
  fieldChanged,
}: {
  p: Product | undefined;
  fieldChanged?: string;
}) => {
  if (!p) {
    return;
  }

  if (fieldChanged === "currentQuantityOfUnit") {
    p.currentQuantityOfUnit = Number(p.currentQuantityOfUnit);
    if (p.currentQuantityOfUnit < p.quantityOfUnit) {
      p.currentQuantityOfUnit = p.quantityOfUnit;
      p.quantity = 1;
      errorSwal(`La quantité d'unité minimum est ${p.quantityOfUnit}`);
    } else if (p.currentQuantityOfUnit % p.quantityOfUnit !== 0) {
      const quantity = Math.ceil(p.currentQuantityOfUnit / p.quantityOfUnit);
      p.currentQuantityOfUnit = quantity * p.quantityOfUnit;
      p.quantity = quantity;
    } else {
      p.quantity = p.currentQuantityOfUnit / p.quantityOfUnit;
    }

    if (p.discount === 0) {
      p.currentPackPrice = formatNumber(
        getPriceWithDiscount({ price: productPrice(p), discount: p.discount })
      );
      p.currentUnitPrice = formatNumber(
        getPriceWithDiscount({
          price: productPrice(p) / p.quantityOfUnit,
          discount: p.discount,
        })
      );
    } else {
      p.finalPrice = calculateSellingPrice({
        marking: p.margin,
        purchasePrice: formatNumber(p.packPurchasePrice * p.quantity),
      });
      p.currentPackPrice = formatNumber(p.finalPrice / p.quantity);
      p.currentUnitPrice = formatNumber(p.finalPrice / p.currentQuantityOfUnit);
      p.discount = formatNumber(
        calculateReduction({
          originalPrice: productPrice(p) / p.quantityOfUnit,
          currentPrice: p.currentUnitPrice,
        })
      );
    }
  }

  if (fieldChanged === "quantity") {
    p.quantity = Number(p.quantity);
    if (p.quantity === 0) {
      p.quantity = 1;
      errorSwal(`La quantité minimum est 1`);
    }
    p.currentQuantityOfUnit = p.quantityOfUnit * p.quantity;

    if (p.discount === 0) {
      p.currentPackPrice = formatNumber(
        getPriceWithDiscount({ price: productPrice(p), discount: p.discount })
      );
      p.currentUnitPrice = formatNumber(
        getPriceWithDiscount({
          price: productPrice(p) / p.quantityOfUnit,
          discount: p.discount,
        })
      );
    } else {
      p.finalPrice = calculateSellingPrice({
        marking: p.margin,
        purchasePrice: formatNumber(p.packPurchasePrice * p.quantity),
      });
      p.currentPackPrice = formatNumber(p.finalPrice / p.quantity);
      p.currentUnitPrice = formatNumber(p.finalPrice / p.currentQuantityOfUnit);
      p.discount = formatNumber(
        calculateReduction({
          originalPrice: productPrice(p) / p.quantityOfUnit,
          currentPrice: p.currentUnitPrice,
        })
      );
    }
  }

  if (!p.currentQuantityOfUnit) {
    p.currentQuantityOfUnit = p.quantityOfUnit;
  }

  if (!p.currentQuantityOfUnit) {
    p.currentQuantityOfUnit = p.quantityOfUnit * p.quantity;
  }

  if (!p.discount) {
    p.discount = 0;
  }

  if (fieldChanged === "currentUnitPrice") {
    p.currentUnitPrice = formatNumber(Number(p.currentUnitPrice));
    p.discount = formatNumber(
      calculateReduction({
        originalPrice: productPrice(p) / p.quantityOfUnit,
        currentPrice: p.currentUnitPrice,
      })
    );
    p.currentPackPrice = formatNumber(p.currentUnitPrice * p.quantityOfUnit);
  } else if (fieldChanged === "currentPackPrice") {
    p.currentPackPrice = formatNumber(Number(p.currentPackPrice));
    p.discount = formatNumber(
      calculateReduction({
        originalPrice: productPrice(p),
        currentPrice: p.currentPackPrice,
      })
    );
    p.currentUnitPrice = formatNumber(p.currentPackPrice / p.quantityOfUnit);
  } else if (fieldChanged === "discount") {
    p.discount = Number(p.discount);

    p.currentPackPrice = formatNumber(
      getPriceWithDiscount({ price: productPrice(p), discount: p.discount })
    );
    p.currentUnitPrice = formatNumber(
      getPriceWithDiscount({
        price: productPrice(p) / p.quantityOfUnit,
        discount: p.discount,
      })
    );
  } else if (fieldChanged === "margin") {
    p.margin = formatNumber(Number(p.margin));
    if (p.margin > 100) {
      p.margin = 100;
    }
    const totalPurchasePrice = formatNumber(p.packPurchasePrice * p.quantity);
    const finalPrice = formatNumber(
      calculateSellingPrice({
        marking: p.margin,
        purchasePrice: totalPurchasePrice,
      })
    );

    p.currentPackPrice = formatNumber(Number(finalPrice) / p.quantity);
    p.discount = formatNumber(
      calculateReduction({
        originalPrice: productPrice(p),
        currentPrice: p.currentPackPrice,
      })
    );
    p.currentUnitPrice = formatNumber(p.currentPackPrice / p.quantityOfUnit);
    p.finalPrice = finalPrice;
  } else if (fieldChanged === "finalPrice") {
    p.currentPackPrice = formatNumber(Number(p.finalPrice) / p.quantity);
    p.discount = formatNumber(
      calculateReduction({
        originalPrice: productPrice(p),
        currentPrice: p.currentPackPrice,
      })
    );
    p.currentUnitPrice = formatNumber(p.currentPackPrice / p.quantityOfUnit);
  } else if (fieldChanged === "none") {
    // p.currentPackPrice = formatNumber(
    //   getPriceWithDiscount({ price: productPrice(p), discount: p.discount })
    // );
    // p.currentUnitPrice = formatNumber(
    //   getPriceWithDiscount({
    //     price: productPrice(p) / p.quantityOfUnit,
    //     discount: p.discount,
    //   })
    // );
  } else if (!fieldChanged) {
    p.currentPackPrice = formatNumber(
      getPriceWithDiscount({ price: productPrice(p), discount: p.discount })
    );
    p.currentUnitPrice = formatNumber(
      getPriceWithDiscount({
        price: productPrice(p) / p.quantityOfUnit,
        discount: p.discount,
      })
    );
  }

  if (fieldChanged !== "finalPrice" && fieldChanged !== "margin") {
    p.finalPrice = formatNumber(p.currentPackPrice * p.quantity);
  }
  if (fieldChanged !== "margin") {
    p.margin = formatNumber(
      getMargin({
        purchasePrice: p.packPurchasePrice * p.quantity,
        price: p.finalPrice,
      })
    );
  }
};

export const willExpireSoon = (invoice: Invoice) => {
  const expireAt = moment(invoice.createdAt).add(3, "month");
  return expireAt.subtract(2, "weeks").isSameOrBefore(moment());
};

const calculateReduction = ({
  originalPrice,
  currentPrice,
}: {
  originalPrice: number;
  currentPrice: number;
}): number => {
  const reductionAmount = originalPrice - currentPrice;
  const reductionPercentage = (reductionAmount / originalPrice) * 100;
  return reductionPercentage;
};

export const getDailyBudgetPerEmployee = ({
  budget,
  numberOfStaff,
}: {
  budget: number | undefined;
  numberOfStaff: number;
}) => {
  if (!budget) {
    return undefined;
  }
  if (numberOfStaff === undefined) {
    return undefined;
  }
  if (numberOfStaff === 0) {
    return 0;
  }

  return budget / numberOfStaff / 21;
};

export const getMonthlyBudget = ({
  amount,
  period,
}: {
  amount: number;
  period: string;
}) => {
  if (amount === undefined) {
    return undefined;
  }
  if (amount === 0) {
    return 0;
  }

  if (period === "day") {
    return amount * 21;
  }

  if (period === "week") {
    return (amount / 5) * 21;
  }

  if (period === "month") {
    return amount;
  }

  if (period === "year") {
    return amount / 12;
  }

  return undefined;
};
