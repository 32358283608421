import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonOrange } from "./Components";
import api from "../auth/api";
import useAuthStore, { LoginResponse } from "../auth/useAuthStore";
import { errorSwal } from "../swal";
const jatLogo = require("../img/jat-logo.png");
const imgAccueil = require("../img/imgAccueil.jpg");

export default function PageLogIn() {
  return (
    <div className="page-log-in">
      <LogIn></LogIn>
      <ImgLogin></ImgLogin>
    </div>
  );
}

function LogIn() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const login = useAuthStore((state) => state.login);
  const loginClick = async (event: any) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const res = await api.get<LoginResponse>("/admin/login", {
        params: { email, password },
      });
      login(res.data);
      navigate("/dashboard");
    } catch (e) {
      errorSwal(e as string);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bloc-jaune">
      <div className="connexion">
        <form>
          <img src={jatLogo} alt="jatLogo" className="jat-logo"></img>

          <h2 style={{ textDecoration: "none" }}> Connexion</h2>
          <div>
            <label>
              Email
              <input
                className="input-30"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </label>
          </div>

          <div style={{ marginTop: "20px" }}>
            <label>
              Password
              <input
                className="input-30"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </label>
          </div>
          <div className="input-connexion">
            <a href="">Mot de passe oublié</a>

            <ButtonOrange
              isLoading={isLoading}
              onClick={loginClick}
              buttonText="Connexion"
            >
              Connexion
            </ButtonOrange>
          </div>
        </form>
      </div>
    </div>
  );
}

function ImgLogin() {
  return (
    <div className="img-accueil">
      <img
        src={imgAccueil}
        style={{ height: "100vh", width: "100%", objectFit: "cover" }}
        alt="imgAccueil"
      ></img>
    </div>
  );
}
