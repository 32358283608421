import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import { ColumnOffer } from "./ViewDevis";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ButtonOrange } from "./Components";
import { Invoice, Product } from "../types";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { updateProduct, formatNumber, getMargin } from "../utils";
import { errorSwal } from "../swal";
import { FaArrowLeft } from "react-icons/fa";
import api from "../auth/api";
import promptSaveInvoice from "../utils/promptSaveInvoice";
export default function FinalPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<Array<Product>>([]);

  useEffect(() => {
    setSelectedProducts(
      (location.state.selectedProducts || []).map((p: Product) => {
        p.margin = formatNumber(p.margin);
        p.discount = formatNumber(p.discount);
        updateProduct({ p, fieldChanged: "none" });
        return p;
      })
    );
  }, []);

  useEffect(() => {
    const getLastPrices = async () => {
      try {
        const res = await api.get<Array<any>>("/admin/invoice/getLastPrices", {
          params: {
            invoiceId: location.state.invoice._id,
            productIds: location.state.selectedProducts.map(
              (p: Product) => p._id
            ),
          },
        });
        setSelectedProducts((curr: Array<Product>) => {
          return curr.map((p: Product) => {
            const specialPrice = (res.data || []).find(
              (sp: any) => sp.productId === p._id
            );
            if (specialPrice) {
              p.lastMargin = formatNumber(
                getMargin({
                  price: specialPrice?.price,
                  purchasePrice: p.packPurchasePrice,
                })
              );
              p.lastPackPrice = formatNumber(specialPrice.price);
              return { ...p };
            }
            return p;
          });
        });
      } catch (e) {
        errorSwal(e as string);
      }
    };
    getLastPrices();
  }, []);

  const editInvoiceProducts = async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const res = await api.post<Invoice>("/admin/invoice/editProducts", {
        products: selectedProducts,
        invoiceId: location.state.invoice._id,
      });
      navigate("/view", { state: { invoice: res.data } });
    } catch (e) {
      errorSwal(e as string);
    } finally {
      setIsLoading(false);
    }
  };
  if (selectedProducts.length === 0) {
    navigate("/search", {
      state: {
        editOnly: location?.state?.editOnly,
        invoice: {
          ...location?.state?.invoice,
          products: selectedProducts,
        },
      },
    });
  }

  return (
    <div style={{ margin: "10px" }}>
      <div
        style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}
      >
        <div style={{ marginRight: "10px" }}>
          <button
            className="icon-button"
            onClick={async () => {
              await promptSaveInvoice({
                invoiceId: location?.state?.invoice?._id,
                selectedProducts,
              });
              navigate("/search", {
                state: {
                  editOnly: location?.state?.editOnly,
                  invoice: {
                    ...location?.state?.invoice,
                    products: selectedProducts,
                  },
                },
              });
            }}
          >
            <FaArrowLeft
              style={{ marginTop: "5px" }}
              size={30}
              className="icon-hover"
            ></FaArrowLeft>
          </button>
        </div>
        <div>
          <h1 style={{ margin: "10px", marginLeft: "20px" }}>
            VOS PRODUITS AU DETAIL
          </h1>
        </div>
      </div>

      <div className="div-final-page">
        <FinalProductTable
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          createdWithClient={location.state.invoice.createdWithClient}
        ></FinalProductTable>
        <div className="div-deux-final-page">
          <ColumnOffer
            selectedProducts={selectedProducts}
            invoice={location.state.invoice}
          ></ColumnOffer>
          <ButtonOrange
            isLoading={isLoading}
            onClick={() => {
              editInvoiceProducts();
            }}
            buttonText={"Confirmer"}
          ></ButtonOrange>
        </div>
      </div>
    </div>
  );
}

function FinalProductTable(props: any) {
  const { selectedProducts, setSelectedProducts, createdWithClient } = props;

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Référence Produit",
          accessor: "n",
          width: 10,
        },
        {
          Header: "Marque",
          accessor: "m",
          width: 60,
        },
        {
          Header: "Produit Choisi",
          accessor: "l",
          width: 200,
        },

        {
          Header: "Quantité",
          accessor: "k",
          width: 10,
        },

        {
          Header: "Tarif par article",
          accessor: "j",
          width: 40,
        },
        {
          Header: "Tarif par pack",
          accessor: "i",
          width: 40,
        },
        {
          Header: "Tarif N-1",
          accessor: "h",
          width: 30,
        },
        {
          Header: "Reduction proposée",
          accessor: "g",
          width: 30,
        },
        {
          Header: "Quantié d'unités",
          accessor: "f",
          width: 30,
        },
        {
          Header: "Tarif par ligne",
          accessor: "e",
          width: 40,
        },
        {
          Header: "Marge N-1",
          accessor: "d",
          width: 40,
        },
        {
          Header: "Evolution Marge",
          accessor: "c",
          width: 40,
        },
        {
          Header: "Marge par ligne",
          accessor: "b",
          width: 40,
        },
        {
          Header: "",
          accessor: "a",
          width: 10,
        },
      ].filter((col: any) =>
        createdWithClient
          ? col.Header !== "Marge par ligne" &&
            col.Header !== "Evolution Marge" &&
            col.Header !== "Marge N-1"
          : true
      ),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: selectedProducts });

  const productsSoted = rows.sort((a: any, b: any) =>
    a.original.brand.localeCompare(b.original.brand)
  );
  const beverages: any[] = [];
  const salty: any[] = [];
  const sweet: any[] = [];
  const coffe: any[] = [];
  const tea: any[] = [];
  const fountain: any[] = [];
  const shipping: any[] = [];
  const other: any[] = [];
  const fruits: any[] = [];

  productsSoted.map((row: any) => {
    if (
      row.original.category === "beverage" ||
      row.original.category === "soda" ||
      row.original.category === "water" ||
      row.original.category === "juice"
    ) {
      beverages.push(row);
    } else if (row.original.category === "salty") {
      salty.push(row);
    } else if (row.original.category === "sweet") {
      sweet.push(row);
    } else if (row.original.category === "coffe") {
      coffe.push(row);
    } else if (row.original.category === "tea") {
      tea.push(row);
    } else if (row.original.category === "fountain") {
      fountain.push(row);
    } else if (row.original.category === "shipping") {
      shipping.push(row);
    } else if (row.original.category === "fruit") {
      fruits.push(row);
    } else {
      other.push(row);
    }
  });

  return (
    <div
      className="scrollbar-final"
      style={{
        flex: 10,
        height: "90vh",
        borderSpacing: "4px 5px",
      }}
    >
      <table
        {...getTableProps()}
        style={{
          width: "100%",
          borderSpacing: "4px 5px",
        }}
      >
        <thead className="tr-final">
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps()}
                  style={{ width: column.width }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {beverages.length ? (
          <>
            <td>
              <p className="table-category">VOS BOISSONS</p>
            </td>
            <tbody {...getTableBodyProps()}>
              {beverages.map((row: any) => {
                prepareRow(row);
                return (
                  // <tr {...row.getRowProps()}>
                  //   {row.cells.map((cell: any) => cell.render("Cell"))}
                  // </tr>
                  <FinalProductCard
                    createdWithClient={createdWithClient}
                    product={row.original}
                    setSelectedProducts={setSelectedProducts}
                  ></FinalProductCard>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {salty.length ? (
          <>
            <p className="table-category">VOS SNACKS SALES</p>
            <tbody {...getTableBodyProps()}>
              {salty.map((row: any) => {
                prepareRow(row);
                return (
                  <FinalProductCard
                    createdWithClient={createdWithClient}
                    product={row.original}
                    setSelectedProducts={setSelectedProducts}
                  ></FinalProductCard>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}

        {sweet.length ? (
          <>
            <p className="table-category">VOS SNACKS SUCRES</p>
            <tbody {...getTableBodyProps()}>
              {sweet.map((row: any) => {
                prepareRow(row);
                return (
                  <FinalProductCard
                    createdWithClient={createdWithClient}
                    product={row.original}
                    setSelectedProducts={setSelectedProducts}
                  ></FinalProductCard>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {coffe.length ? (
          <>
            <p className="table-category">VOS SOLUTIONS CAFE</p>
            <tbody {...getTableBodyProps()}>
              {coffe.map((row: any) => {
                prepareRow(row);
                return (
                  <FinalProductCard
                    createdWithClient={createdWithClient}
                    product={row.original}
                    setSelectedProducts={setSelectedProducts}
                  ></FinalProductCard>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {tea.length ? (
          <>
            <p className="table-category">THE ET TISANE</p>
            <tbody {...getTableBodyProps()}>
              {tea.map((row: any) => {
                prepareRow(row);
                return (
                  <FinalProductCard
                    createdWithClient={createdWithClient}
                    product={row.original}
                    setSelectedProducts={setSelectedProducts}
                  ></FinalProductCard>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {fruits.length ? (
          <>
            <p className="table-category">Fruits</p>
            <tbody {...getTableBodyProps()}>
              {fruits.map((row: any) => {
                prepareRow(row);
                return (
                  <FinalProductCard
                    createdWithClient={createdWithClient}
                    product={row.original}
                    setSelectedProducts={setSelectedProducts}
                  ></FinalProductCard>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {fountain.length ? (
          <>
            <p className="table-category">VOTRE SOLUTIONS FONTAINE</p>
            <tbody {...getTableBodyProps()}>
              {fountain.map((row: any) => {
                prepareRow(row);
                return (
                  <FinalProductCard
                    createdWithClient={createdWithClient}
                    product={row.original}
                    setSelectedProducts={setSelectedProducts}
                  ></FinalProductCard>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {shipping.length ? (
          <>
            <td>
              <p className="table-category">VOS LIVRAISONS ET AGENCEMENT</p>
            </td>
            <tbody {...getTableBodyProps()}>
              {shipping.map((row: any) => {
                prepareRow(row);
                return (
                  <FinalProductCard
                    createdWithClient={createdWithClient}
                    product={row.original}
                    setSelectedProducts={setSelectedProducts}
                  ></FinalProductCard>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {other.length ? (
          <>
            <td>
              <p className="table-category">AUTRES</p>
            </td>
            <tbody {...getTableBodyProps()}>
              {other.map((row: any) => {
                prepareRow(row);
                return (
                  <FinalProductCard
                    createdWithClient={createdWithClient}
                    product={row.original}
                    setSelectedProducts={setSelectedProducts}
                  ></FinalProductCard>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
      </table>
    </div>
  );
}

const FinalProductCard = ({
  createdWithClient,
  product,
  setSelectedProducts,
}: {
  createdWithClient: boolean;
  product: Product;
  setSelectedProducts: Function;
}) => {
  let backgroundColor = "#ffffff";
  if (product.origin === "excel") {
    backgroundColor = "#eec69f";
  }
  if (product.lastMargin && product.lastMargin > product.margin) {
    backgroundColor = "#f1d9d9";
  }

  return (
    <tr style={{ width: "100%" }} key={product._id}>
      <td
        className="item"
        style={{ textAlign: "center", backgroundColor }}
        width={10}
      >
        {product.reference}
      </td>
      <td
        className="item"
        style={{ textAlign: "center", backgroundColor }}
        width={10}
      >
        {product?.brand || "-"}
      </td>
      <td
        className="item"
        style={{ textAlign: "center", backgroundColor }}
        width={10}
      >
        {product.name}
      </td>

      <td style={{ textAlign: "center" }} className="input-item">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            className="input-table"
            type="text"
            style={{ textAlign: "center", backgroundColor }}
            value={product.quantity}
            onInput={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.quantity = Number(e.target.value);
                return [...curr.map((p) => ({ ...p }))];
              });
            }}
            onBlur={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.quantity = Number(e.target.value);
                updateProduct({ p: product, fieldChanged: "quantity" });
                return [...curr.map((p) => ({ ...p }))];
              });
            }}
          />
        </div>
      </td>

      <td style={{ textAlign: "center" }} className="input-item">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            className="input-table"
            type="text"
            value={product.currentUnitPrice}
            style={{ textAlign: "center", backgroundColor }}
            onInput={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.currentUnitPrice = e.target.value;
                return [...curr];
              });
            }}
            onBlur={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.currentUnitPrice = Number(e.target.value);
                updateProduct({ p: product, fieldChanged: "currentUnitPrice" });
                return [...curr];
              });
            }}
          />

          <span
            style={{
              position: "absolute",
              right: "5px",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            €
          </span>
        </div>
      </td>

      <td style={{ textAlign: "center" }} className="input-item">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            className="input-table"
            type="text"
            style={{ textAlign: "center", backgroundColor }}
            value={product.currentPackPrice}
            onInput={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.currentPackPrice = e.target.value;
                return [...curr];
              });
            }}
            onBlur={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.currentPackPrice = Number(e.target.value);
                updateProduct({ p: product, fieldChanged: "currentPackPrice" });
                return [...curr];
              });
            }}
          />

          <span
            style={{
              position: "absolute",
              right: "5px",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            €
          </span>
        </div>
      </td>

      <td
        className="item"
        style={{ textAlign: "center", backgroundColor }}
        width={10}
      >
        {product.lastPackPrice ? `${product.lastPackPrice}€` : "-"}
      </td>

      <td style={{ textAlign: "center" }} className="input-item">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            className="input-table"
            type="text"
            style={{
              textAlign: "center",
              backgroundColor:
                product.discount < 0 ? "#f1d9d9" : backgroundColor,
            }}
            value={product.discount}
            onInput={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.discount = e.target.value;
                return [...curr];
              });
            }}
            onBlur={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.discount = Number(e.target.value);
                updateProduct({ p: product, fieldChanged: "discount" });
                return [...curr];
              });
            }}
          />
          <span
            style={{
              position: "absolute",
              right: "5px",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            %
          </span>
        </div>
      </td>

      <td style={{ textAlign: "center" }} className="input-item">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            className="input-table"
            type="text"
            style={{ textAlign: "center", backgroundColor }}
            value={product.currentQuantityOfUnit}
            onInput={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.currentQuantityOfUnit = Number(e.target.value);
                return [...curr];
              });
            }}
            onBlur={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.currentQuantityOfUnit = Number(e.target.value);
                updateProduct({
                  p: product,
                  fieldChanged: "currentQuantityOfUnit",
                });
                return [...curr];
              });
            }}
          />
        </div>
      </td>

      <td style={{ textAlign: "center" }} className="input-item">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            className="input-table"
            type="text"
            style={{ textAlign: "center", backgroundColor }}
            value={product.finalPrice}
            onInput={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.finalPrice = e.target.value;
                return [...curr];
              });
            }}
            onBlur={(e: any) => {
              setSelectedProducts((curr: Array<Product>) => {
                product.finalPrice = Number(e.target.value);
                updateProduct({ p: product, fieldChanged: "finalPrice" });
                return [...curr];
              });
            }}
          />
          <span
            style={{
              position: "absolute",
              right: "5px",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            €
          </span>
        </div>
      </td>

      {!createdWithClient ? (
        <td
          className="item"
          style={{ textAlign: "center", backgroundColor }}
          width={10}
        >
          {product.lastMargin ? `${product.lastMargin}%` : "-"}
        </td>
      ) : (
        ""
      )}
      {!createdWithClient ? (
        <td
          className="item"
          style={{ textAlign: "center", backgroundColor }}
          width={10}
        >
          {product.lastMargin !== undefined && product.margin !== undefined
            ? `${formatNumber(product.margin - product.lastMargin)}%`
            : "-"}
        </td>
      ) : (
        ""
      )}

      {!createdWithClient ? (
        <td style={{ textAlign: "center" }} className="input-item">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <input
              className="input-table"
              type="text"
              style={{ textAlign: "center", backgroundColor }}
              value={product.margin}
              onInput={(e: any) => {
                setSelectedProducts((curr: Array<Product>) => {
                  product.margin = e.target.value;
                  return [...curr];
                });
              }}
              onBlur={(e: any) => {
                setSelectedProducts((curr: Array<Product>) => {
                  product.margin = Number(e.target.value);
                  updateProduct({ p: product, fieldChanged: "margin" });
                  return [...curr];
                });
              }}
            />

            <span
              style={{
                position: "absolute",
                right: "5px",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              %
            </span>
          </div>
        </td>
      ) : (
        ""
      )}

      <td style={{ textAlign: "center" }}>
        <button
          className="icon-button"
          onClick={() =>
            setSelectedProducts((curr: Array<Product>) =>
              curr.filter(({ _id }) => _id !== product._id)
            )
          }
        >
          <RiDeleteBin2Fill
            size={20}
            className="icon-hover"
            style={{ color: "red" }}
          ></RiDeleteBin2Fill>
        </button>
      </td>
    </tr>
  );
};
