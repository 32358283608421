import { useNavigate } from "react-router";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import promptSaveInvoice from "../utils/promptSaveInvoice";

function SearchBlock(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { filterCategory, setFilterCategory, search, setSearch } = props;

  const manageToggleCategory = ({
    checked,
    val,
  }: {
    checked: boolean;
    val: any;
  }) => {
    if (checked) {
      setSearch("");
      setFilterCategory(val);
    } else {
      setSearch("");
      setFilterCategory(undefined);
    }
  };

  return (
    <div className="div-search-block">
      <button
        className="icon-button"
        onClick={async () => {
          await promptSaveInvoice({
            invoiceId: location?.state?.invoice?._id,
            selectedProducts: props.selectedProducts,
          });
          location?.state?.editOnly
            ? navigate("/view", {
                state: { invoice: location?.state?.invoice },
              })
            : navigate("/create", {
                state: { invoice: location?.state?.invoice },
              });
        }}
      >
        <FaArrowLeft
          style={{ marginTop: "5px" }}
          size={20}
          className="icon-hover"
        ></FaArrowLeft>
      </button>
      <h3>Filtrer</h3>
      <div className="search-block">
        <input
          className="search-bar"
          type="text"
          value={search}
          onInput={(e: any) => {
            setSearch(e.target.value || "");
            setFilterCategory(undefined);
          }}
          placeholder="Recherche..."
        ></input>
        <div className="filter-box">
          <FilterCheckBox
            label="Snacking salé"
            value="salty"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />

          <FilterCheckBox
            label="Snacking sucré"
            value="sweet"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />

          <FilterCheckBox
            label="Boissons"
            value="beverage"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />

          <FilterCheckBox
            label="Jus"
            value="juice"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />

          <FilterCheckBox
            label="Sodas"
            value="soda"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />

          <FilterCheckBox
            label="Eaux"
            value="water"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />

          <FilterCheckBox
            label="Café"
            value="coffe"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />
          <FilterCheckBox
            label="Thés et tisanes et thé glacés"
            value="tea"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />
          <FilterCheckBox
            label="Guest Experience"
            value="guest"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />
          <FilterCheckBox
            label="Fruits"
            value="fruit"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />
          <FilterCheckBox
            label="Entretien"
            value="hygiene"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />
          <FilterCheckBox
            label="Mobilier & agencement"
            value="furniture"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />
          <FilterCheckBox
            label="Autre"
            value="other"
            filterCategory={filterCategory}
            manageToggleCategory={manageToggleCategory}
          />
        </div>
      </div>
    </div>
  );
}

export default SearchBlock;

export const FilterCheckBox = (props: any) => {
  const { label, value, filterCategory, manageToggleCategory } = props;
  return (
    <div className="checkbox">
      <input
        type="checkbox"
        checked={filterCategory === value}
        onChange={(e) =>
          manageToggleCategory({
            checked: e.target.checked,
            val: value,
          })
        }
      ></input>
      <label className="search-label">{label}</label>
    </div>
  );
};
