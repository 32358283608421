import Swal from "sweetalert2";
import "animate.css";

export const promptSwal = ({
  title,
  text,
  cancelButtonText,
}: {
  title?: string;
  text?: string;
  cancelButtonText?: string;
}) =>
  Swal.fire({
    title,
    text,
    icon: "warning",
    iconColor: "#c4664a",
    showCancelButton: true,
    confirmButtonColor: "#72767d",
    cancelButtonColor: "#c4664a",
    cancelButtonText: cancelButtonText || "Annuler",
    confirmButtonText: "Oui",
  });

export const successSwal = (title: string) =>
  Swal.fire({
    title: title,
    confirmButtonColor: "#c4664a",
    confirmButtonText: "OK",
  });

export const errorSwal = (text: string) =>
  Swal.fire({
    icon: "error",
    iconColor: "#c4664a",
    title: "Oops...",
    text: text || "Une erreur s'est produite",
    confirmButtonColor: "#c4664a",
    confirmButtonText: "OK",
  });

export const infoSwal = (text: string) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "#c4664a",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,

    timerProgressBar: true,
  });
  Toast.fire({
    icon: "success",
    text,
    timer: 2000,
  });
};

export const editNameSwal = async () =>
  Swal.fire({
    title: "Dupliquer une offre",
    input: "text",
    inputLabel: "Nom de la nouvelle offre :",
    inputPlaceholder: "Entrer le nom de la nouvelle offre",
    confirmButtonColor: "#72767d",
    showCancelButton: true,
    cancelButtonColor: "#c4664a",
    cancelButtonText: "Annuler",
  });

//   export const infoSwal = (text : string) =>

// Swal.fire({
//   title: 'Sûr(e) de vouloir supprimer ?',
//   text: "Vous ne pourrez plus revenir en arrière",
//   icon: 'warning',
//   iconColor:'#c4664a',
//   showCancelButton: true,
//   confirmButtonColor: '#72767d',
//   cancelButtonColor: '#c4664a',
//   cancelButtonText: 'Annuler',
//   confirmButtonText: 'Oui, supprimer'
// }).then((result) => {
//   if (result.isConfirmed) {
//     Swal.fire(
//       {
//         title: 'Supprimé !',
//         text: "Ce fichier a bien été supprimé",
//         icon: 'success',

//         confirmButtonColor: '#c4664a',

//         confirmButtonText: 'ok'
//       }

//     )
//   }
// })

// Swal.fire({
// title: 'Tout a fonctionné !',
//  text: "",

//     confirmButtonColor: '#c4664a',
//     confirmButtonText: 'OK'
//   }
// )

// Swal.fire({
//   icon: 'error',
//   iconColor:'#c4664a',
//   title: 'Oops...',
//   text: 'Something went wrong!',
//   confirmButtonColor: '#c4664a',
//  confirmButtonText: 'OK',

// })

// const Toast = Swal.mixin({
//   toast: true,
//   position: 'top-right',
//   iconColor: '#c4664a',
//   customClass: {
//     popup: 'colored-toast'
//   },
//   showConfirmButton: false,

//   timerProgressBar: true
// })

// Toast.fire({
//   icon: 'question',
//   text: 'information'
// })
