// import { TailSpin } from "react-loader-spinner";
export const ButtonOrange = (props: any) => {
  const { buttonText, onClick, isLoading } = props;
  return (
    <button
      disabled={isLoading}
      onClick={onClick}
      className="button-orange"
      style={props.style}
    >
      {
        buttonText
      }
    </button>
  );
};
