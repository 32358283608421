import { useState, useEffect } from "react";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import { FiDownload } from "react-icons/fi";
import { FiEdit, FiCheck } from "react-icons/fi";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ButtonOrange } from "./Components";
import { useTable, useSortBy } from "react-table";
import moment from "moment";
import api from "../auth/api";
import { Invoice } from "../types";
import { formatNumber, willExpireSoon } from "../utils";
import { errorSwal, promptSwal, infoSwal, editNameSwal } from "../swal";
import useAuthStore from "../auth/useAuthStore";
import { saveAs } from "file-saver";

const statusActionDic = {
  confirmed: "valider",
  refused: "annuler",
  deleted: "supprimer",
};

export default function PageListDevis() {
  const { currentUser } = useAuthStore();
  const [invoices, setInvoices] = useState<Array<Invoice>>([]);
  const [statusFilter, setStatusFilter] = useState<string>("active");
  const [stats, setStats] = useState<any>();

  const refreshInvoices = async () => {
    try {
      const invoices = await api.get<[Invoice]>("/admin/invoice/get");
      setInvoices(
        invoices.data.sort((a: Invoice, b: Invoice) =>
          moment(a.updatedAt).isAfter(b.updatedAt) ? -1 : 1
        )
      );
    } catch (error) {
      errorSwal("Une erreur s'est produite lors de la récupération des devis");
      console.error("Error fetching data: ", error);
    }
  };

  const getStats = async () => {
    try {
      const statsResponse = await api.get<any>("/admin/invoice/getStats");
      if (statsResponse.data) {
        setStats(statsResponse.data);
      }
    } catch (error) {
      errorSwal("Une erreur s'est produite lors de la récupération des devis");
      console.error("Error fetching data: ", error);
    }
  };

  const refreshDashboard = async () => {
    await refreshInvoices();
    await getStats();
  };

  useEffect(() => {
    refreshDashboard();
  }, []);

  const duplicateInvoice = async (invoiceId: string) => {
    const { value } = await editNameSwal();
    if (value) {
      try {
        await api.post<[Invoice]>("/admin/invoice/duplicate", {
          invoiceId,
          name: value,
        });
        await refreshDashboard();
        infoSwal("L'offre a bien été dupliquée");
      } catch (error) {
        errorSwal(error as string);
        console.error("Error fetching data: ", error);
      }
    }
  };

  const editInvoiceStatus = async ({
    invoiceId,
    status,
  }: {
    invoiceId: string;
    status: keyof typeof statusActionDic;
  }) => {
    promptSwal({
      title: `Voulez vous ${statusActionDic[status]} cette offre ?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.post<[Invoice]>("/admin/invoice/editStatus", {
            invoiceId,
            status,
          });
          await refreshDashboard();
          infoSwal(`L'offre a bien été ${statusActionDic[status]}`);
        } catch (error) {
          errorSwal(error as string);
          console.error("Error fetching data: ", error);
        }
      }
    });
  };

  const importProducts = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";
    input.click();
    input.onchange = async function (event: Event) {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (files?.[0]) {
        const formData = new FormData();
        formData.append("file", files[0], "file_upload");
        formData.append("upload_file", "true");

        try {
          const response = await api.post("/admin/importProducts", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.status === 200) {
            infoSwal("Le fichier a bien été importé");
          }
        } catch (e) {
          errorSwal("Une erreur s'est produite lors de l'import du fichier");
        }
      }
    };
  };

  const downloadPdf = async (invoiceId: string) => {
    try {
      const res = await api.get<any>("/admin/invoice/download", {
        params: { invoiceId },
        responseType: "blob",
      });
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      saveAs(pdfBlob, "download.pdf");
    } catch (error) {
      errorSwal(error as string);
      console.error("Error fetching data: ", error);
    }
  };

  const navigate = useNavigate();
  return (
    <div className="page-list-devis">
      <div
        style={{
          display: "flex",
          flex: 10,
          alignItems: "center",
          justifyContent: "space-between",
          marginRight: "50px",
        }}
      >
        <div className="header-new-devis">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              marginLeft: "55px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "50px",
                alignItems: "center",
              }}
            >
              <h1> Les offres de {currentUser?.name}</h1>
              <div>
                <div>
                  <select
                    style={{ width: "200px" }}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    placeholder="Filtre offre"
                  >
                    <option value="active">Offres en cours</option>
                    <option value="all">Toutes les offres</option>
                    <option value="confirmed">Offres validés</option>
                    <option value="refused">Offres refusés</option>
                    <option value="expired">Offres expirés</option>
                    <option value="deleted">Offres supprimés</option>
                  </select>
                </div>
              </div>
            </div>
            {stats ? (
              <div
                className="header-dashboard"
                style={{
                  display: "flex",

                  justifyContent: "space-between",
                  marginRight: "50px",
                }}
              >
                <div className="line-stat">
                  <Statistiques title={"Pipe Global"} stat={stats?.pipe} />
                  <Statistiques title={"Delta Pipe"} stat={stats?.deltaPipe} />
                  <Statistiques
                    title={"Offres en cours"}
                    stat={stats?.active}
                  />
                  <Statistiques
                    title={"Offres expirés"}
                    stat={stats?.expired}
                  />
                </div>
                <div className="line-stat">
                  <Statistiques
                    title={"Offres validés"}
                    stat={stats?.confirmed}
                  />
                  <Statistiques
                    title={"Offres envoyées"}
                    stat={{ current: undefined, previous: undefined }}
                  />
                  <Statistiques title={"Offres perdus"} stat={stats?.refused} />
                  <StatistiquesSpecial
                    title={"Taux de conversion"}
                    stat={stats?.conversion}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="header-new-devis-btn">
            <ButtonOrange
              onClick={() => navigate("/create")}
              buttonText="New"
              style={{ fontWeight: "bold", border: "none" }}
            ></ButtonOrange>
            <ButtonOrange
              style={{
                backgroundColor: "#21247c",
                fontWeight: "bold",
                border: "none",
              }}
              onClick={importProducts}
              buttonText="CSV"
            ></ButtonOrange>
          </div>
        </div>
      </div>

      <ListDevis
        duplicateInvoice={duplicateInvoice}
        downloadPdf={downloadPdf}
        editInvoiceStatus={editInvoiceStatus}
        items={(invoices || []).filter((invoice) => {
          if (statusFilter === "all") {
            return true;
          }
          return invoice.status === statusFilter;
        })}
      ></ListDevis>
    </div>
  );
}

function ListDevis(props: any) {
  const { currentUser } = useAuthStore();
  const { items, duplicateInvoice, editInvoiceStatus, downloadPdf } = props;
  const navigate = useNavigate();
  const defaultCell = ({ cell }: { cell: any }) => (
    <td
      style={
        willExpireSoon(cell.row.original)
          ? { textAlign: "center", backgroundColor: "#eec69f" }
          : { textAlign: "center" }
      }
      className="item-line"
      {...cell.getCellProps()}
    >
      {cell.value}
    </td>
  );

  const dateCell = ({ cell }: { cell: any }) => (
    <td
      style={
        willExpireSoon(cell.row.original)
          ? { textAlign: "center", backgroundColor: "#eec69f" }
          : { textAlign: "center" }
      }
      className="item-line"
      {...cell.getCellProps()}
    >
      {moment(cell.value).format("DD/MM/YYYY")}
    </td>
  );

  const crossCell = ({ cell }: { cell: any }) =>
    cell.row.original.status === "active" ? (
      <td className="logo-line" {...cell.getCellProps()}>
        <button
          className="icon-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editInvoiceStatus({
              invoiceId: cell.row.original._id,
              status: "confirmed",
            });
          }}
        >
          <FiCheck
            className="icon-hover"
            size={25}
            style={{ color: "green", margin: " 0px 15px" }}
          ></FiCheck>
        </button>
        <button
          className="icon-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editInvoiceStatus({
              invoiceId: cell.row.original._id,
              status: "refused",
            });
          }}
        >
          <RxCross1
            className="icon-hover"
            size={25}
            style={{ color: "red", margin: "0px 15px" }}
          ></RxCross1>
        </button>
      </td>
    ) : (
      <td></td>
    );

  const editCell = ({ cell }: { cell: any }) =>
    cell.row.original.status === "active" ? (
      <td className="logo-line" {...cell.getCellProps()}>
        <button
          className="icon-button"
          onClick={(e) => {
            e.stopPropagation();
            navigate("/view", { state: { invoice: cell.row.original } });
          }}
        >
          <FiEdit
            className="icon-hover"
            size={20}
            style={{ color: "#c4664a" }}
          ></FiEdit>
        </button>
      </td>
    ) : (
      <td className="logo-line" {...cell.getCellProps()}></td>
    );
  const downCell = ({ cell }: { cell: any }) => (
    <td className="logo-line" {...cell.getCellProps()}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          downloadPdf(cell.row.original._id);
        }}
        className="icon-button"
      >
        <FiDownload
          className="icon-hover"
          size={20}
          style={{ color: "blue" }}
        ></FiDownload>
      </button>
    </td>
  );

  const duplicateCell = ({ cell }: { cell: any }) => (
    <td className="logo-line" {...cell.getCellProps()}>
      <button
        className="icon-button"
        onClick={(e) => {
          e.stopPropagation();
          duplicateInvoice(cell.row.original._id);
        }}
      >
        <HiOutlineDocumentDuplicate
          size={20}
          className="icon-hover"
          style={{ color: "grey" }}
        ></HiOutlineDocumentDuplicate>
      </button>
    </td>
  );

  const deleteCell = ({ cell }: { cell: any }) =>
    cell.row.original.status === "deleted" ? (
      <td className="logo-line" {...cell.getCellProps()}></td>
    ) : (
      <td className="logo-line" {...cell.getCellProps()}>
        <button
          className="icon-button"
          onClick={(e) => {
            e.stopPropagation();
            editInvoiceStatus({
              invoiceId: cell.row.original._id,
              status: "deleted",
            });
          }}
        >
          <RiDeleteBin2Fill size={20} className="icon-hover"></RiDeleteBin2Fill>
        </button>
      </td>
    );

  const sortDate = (rowA: any, rowB: any, columnName: any) => {
    if (columnName === "Date de modification") {
      columnName = "createdAt";
    }
    let a = rowA.values[columnName];
    let b = rowB.values[columnName];
    return moment(a).isBefore(moment(b)) ? -1 : 1;
  };

  const columns = React.useMemo(() => {
    return currentUser?.isSuperAdmin
      ? [
          {
            Header: "Valider",
            accessor: "id",
            width: 80,
            Cell: crossCell,
          },
          {
            Header: "Commercial",
            accessor: "owner",
            width: 80,
            Cell: defaultCell,
          },
          {
            Header: "Date de création",
            accessor: "createdAt",
            width: 20,
            Cell: dateCell,
            sortType: sortDate,
          },
          {
            Header: "Date de modification",
            accessor: "updatedAt",
            width: 20,
            Cell: dateCell,
            sortType: sortDate,
          },
          {
            Header: "Date d'expiration",
            accessor: (invoice: any) =>
              moment(invoice.createdAt).add(3, "month"),
            width: 20,
            Cell: dateCell,
            sortType: sortDate,
          },

          {
            Header: "Client",
            accessor: "company",
            width: 100,
            Cell: defaultCell,
          },

          {
            Header: "Nom de l'offre",
            accessor: "name",
            width: 100,
            Cell: defaultCell,
          },
          {
            Header: "Nombre de Ref",
            accessor: (invoice: any) => invoice?.products?.length || 0,
            width: 80,
            Cell: defaultCell,
          },
          {
            Header: "Montant",
            accessor: (invoice: any) => formatNumber(invoice?.price) + " €",
            width: 70,
            Cell: defaultCell,
          },
          {
            Header: "Marge",
            accessor: (invoice: any) => formatNumber(invoice?.margin) + " %",
            width: 40,
            Cell: defaultCell,
          },
          {
            Header: "",
            accessor: "download",
            width: 10,
            Cell: downCell,
          },
          {
            Header: "",
            accessor: "edit",
            width: 10,
            Cell: editCell,
          },

          {
            Header: "",
            accessor: "duplicate",
            width: 10,
            Cell: duplicateCell,
          },
          {
            Header: "",
            accessor: "delete",
            width: 10,
            Cell: deleteCell,
          },
        ]
      : [
          {
            Header: "Valider",
            accessor: "id",
            width: 80,
            Cell: crossCell,
          },
          {
            Header: "Date de création",
            accessor: "createdAt",
            width: 20,
            Cell: dateCell,
            sortType: sortDate,
          },
          {
            Header: "Date de modification",
            accessor: "updatedAt",
            width: 20,
            Cell: dateCell,
            sortType: sortDate,
          },
          {
            Header: "Date d'expiration",
            accessor: (invoice: any) =>
              moment(invoice.createdAt).add(3, "month"),
            width: 20,
            Cell: dateCell,
            sortType: sortDate,
          },

          {
            Header: "Client",
            accessor: "company",
            width: 100,
            Cell: defaultCell,
          },

          {
            Header: "Nom de l'offre",
            accessor: "name",
            width: 100,
            Cell: defaultCell,
          },
          {
            Header: "Nombre de Ref",
            accessor: (invoice: any) => invoice?.products?.length || 0,
            width: 80,
            Cell: defaultCell,
          },
          {
            Header: "Montant",
            accessor: (invoice: any) => formatNumber(invoice?.price) + " €",
            width: 70,
            Cell: defaultCell,
          },
          {
            Header: "Marge",
            accessor: (invoice: any) => formatNumber(invoice?.margin) + " %",
            width: 40,
            Cell: defaultCell,
          },
          {
            Header: "",
            accessor: "download",
            width: 10,
            Cell: downCell,
          },
          {
            Header: "",
            accessor: "edit",
            width: 10,
            Cell: editCell,
          },

          {
            Header: "",
            accessor: "duplicate",
            width: 10,
            Cell: duplicateCell,
          },
          {
            Header: "",
            accessor: "delete",
            width: 10,
            Cell: deleteCell,
          },
        ];
  }, []);

  console.log(items);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: items }, useSortBy);

  return (
    <table
      {...getTableProps()}
      style={{
        width: "100%",
        height: "100%",
        borderSpacing: "5px 15px",
      }}
    >
      <thead>
        {headerGroups.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th
                className="th-list-devis"
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{ width: column.width }}
              >
                {column.render("Header")}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <MdArrowDropDown />
                  ) : (
                    <MdArrowDropUp />
                  )
                ) : (
                  ""
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              onClick={() => {
                navigate("/view", { state: { invoice: row.original } });
              }}
            >
              {row.cells.map((cell: any) => cell.render("Cell"))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function Statistiques(props: any) {
  const { title, stat } = props;

  const { current, previous } = stat;

  return (
    <div className="div-stat">
      <h3 className="title-stat">{title}</h3>
      <div className="div-stat-1">
        {current?.count !== undefined ? (
          <p className="p-big">{formatNumber(current.count)} offres</p>
        ) : (
          <></>
        )}
        {current?.profit !== undefined ? (
          <p className="p-big">{formatNumber(current.profit)}€</p>
        ) : (
          <></>
        )}
      </div>
      <div className="div-stat-1">
        {previous?.count !== undefined ? (
          <p className="p-little">{formatNumber(previous.count)} offres</p>
        ) : (
          <></>
        )}
        {previous?.profit !== undefined ? (
          <p className="p-little">{formatNumber(previous.profit)}€</p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function StatistiquesSpecial(props: any) {
  const { title, stat } = props;

  const { current, previous } = stat;

  return (
    <div className="div-stat">
      <h3 className="title-stat">{title}</h3>
      <div className="div-stat-1" style={{ justifyContent: "space-around" }}>
        {current?.count !== undefined ? (
          <p className="p-big">{formatNumber(current.count)}%</p>
        ) : (
          <></>
        )}
      </div>
      <div className="div-stat-1" style={{ justifyContent: "space-around" }}>
        {previous?.count !== undefined ? (
          <p className="p-little">{formatNumber(previous.count)}%</p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
