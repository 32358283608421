import { Navigate } from "react-router-dom";
import useAuthStore from "./useAuthStore";

const AuthContainer = ({
  children,
  requireAuth,
  name,
}: {
  children: React.ReactNode;
  requireAuth: boolean;
  name: string;
}) => {
  const { currentUser } = useAuthStore();
  if (requireAuth && !currentUser) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};
export default AuthContainer;
