import React, { useEffect, useState } from "react";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { useTable } from "react-table";
import ExcelJS from "exceljs";
import { FiDownload, FiEdit, FiCheck } from "react-icons/fi";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { Invoice, Product } from "../types";
import { FaArrowLeft } from "react-icons/fa";
import {
  displayNumber,
  formatNumber,
  divideNumbers,
  getMargin,
  getValueLabel,
  getDailyBudgetPerEmployee,
  getMonthlyBudget,
  displayVal,
} from "../utils";
import { useLocation } from "react-router-dom";
import api from "../auth/api";
import moment from "moment";
import { errorSwal, promptSwal, infoSwal, editNameSwal } from "../swal";
const periodDic = {
  day: "Jour",
  week: "Semaine",
  month: "Mois",
  year: "Année",
};

const statusTextDic = {
  confirmed: "validé",
  refused: "annulé",
  active: "en cours",
  expired: "expiré",
  deleted: "supprimé",
};

const contractTypeDic = {
  fidelity: "Fidélité",
  basket: "Panier",
  fruits: "Fruits",
  beverage: "Boissons chaudes",
};

export default function SaveDevis() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invoice, setInvoice] = useState<Invoice>(location.state.invoice);

  useEffect(() => {
    const getProducts = async () => {
      try {
        setIsLoading(true);
        const products = await api.get<[Product]>("/admin/invoice/products", {
          params: { invoiceId: invoice._id },
        });
        setInvoice((curr: Invoice) => ({
          ...invoice,
          products: products.data,
        }));
        setIsLoading(false);
      } catch (error) {
        errorSwal(
          "Une erreur s'est produite lors de la récupération des devis"
        );
        console.error("Error fetching data: ", error);
        navigate("/dashboard");
        setIsLoading(false);
      }
    };

    getProducts();
  }, []);

  useEffect(() => {
    const getLastPrices = async () => {
      try {
        const res = await api.get<Array<any>>("/admin/invoice/getLastPrices", {
          params: {
            invoiceId: invoice._id,
            productIds: invoice.products.map((p: Product) => p._id),
          },
        });
        setInvoice((invoice: Invoice) => {
          const productWithLastPrice = invoice.products.map((p: Product) => {
            const specialPrice = (res.data || []).find(
              (sp: any) => sp.productId === p._id
            );
            if (specialPrice) {
              p.lastMargin = formatNumber(
                getMargin({
                  price: specialPrice?.price,
                  purchasePrice: p.packPurchasePrice,
                })
              );
              p.lastPackPrice = formatNumber(specialPrice.price);
            }
            return p;
          });

          return {
            ...invoice,
            products: productWithLastPrice,
          };
        });
      } catch (e) {
        errorSwal(e as string);
      }
    };
    getLastPrices();
  }, []);

  return (
    <div className="watch-devis" style={{ marginRight: "20px" }}>
      <div
        style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}
      >
        <div style={{ marginRight: "10px" }}>
          <button
            className="icon-button"
            onClick={() => navigate("/dashboard")}
          >
            <FaArrowLeft
              style={{ marginTop: "5px" }}
              size={30}
              className="icon-hover"
            ></FaArrowLeft>
          </button>
        </div>
        <div>
          <h1 style={{ margin: "10px", marginLeft: "20px", fontSize: "24px" }}>
            {invoice.name} - {invoice.number} - {invoice.company} -{" "}
            {statusTextDic[invoice.status]}
          </h1>
        </div>
      </div>
      <div className="div-page-devis">
        <div style={{ flex: 10, marginRight: "20px" }}>
          <InfoDevis invoice={invoice}></InfoDevis>

          {isLoading ? (
            <></>
          ) : (
            <TableProduct
              products={invoice.products}
              createdWithClient={invoice.createdWithClient}
            ></TableProduct>
          )}
        </div>

        {isLoading ? (
          <></>
        ) : (
          <div className="column">
            <ColumnOffer
              invoice={invoice}
              selectedProducts={invoice.products}
              style={{ margin: "20px 40px 20px 0px" }}
            ></ColumnOffer>
            <ButtonAction invoice={invoice}></ButtonAction>
          </div>
        )}
      </div>
    </div>
  );
}

function InfoDevis(props: any) {
  const navigate = useNavigate();
  const { invoice }: { invoice: Invoice } = props;
  return (
    <div className="bloc-info-devis">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Informations</h2>
        <button
          onClick={() =>
            navigate("/create", { state: { invoice, editOnly: true } })
          }
          className="button-orange"
        >
          Modifier
        </button>
      </div>

      <div className="info-devis">
        <div className="info-organisation">
          <p style={{ fontSize: "smaller" }}>
            <u>Code Client</u>: {invoice.clientCode || "-"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Date de création:</u>{" "}
            {moment(invoice.createdAt).format("DD/MM/YYYY")}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Date d'expiration:</u>{" "}
            {moment(invoice.createdAt).add(3, "month").format("DD/MM/YYYY")}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Organisation:</u> {invoice.company || "-"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Type de contrat:</u>{" "}
            {contractTypeDic[
              invoice.contractType as keyof typeof contractTypeDic
            ] || "-"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Nombre de livraison par mois:</u>{" "}
            {invoice.shippingPerMonth || "NA"}
          </p>
        </div>
        <div className="info-contact">
          <p style={{ fontSize: "smaller" }}>
            <u>Prénom:</u> {invoice?.contact?.firstName || "-"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Nom:</u> {invoice?.contact?.lastName || "-"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Poste:</u> {invoice?.contact?.job || "-"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Mail:</u> {invoice?.contact?.email || "-"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Téléphone:</u> {invoice?.contact?.phone || "-"}
          </p>
        </div>
        <div className="info-budget">
          <p style={{ fontSize: "smaller" }}>
            <u>Budget:</u>{" "}
            {invoice.budget &&
            invoice.budget?.amount !== undefined &&
            invoice.budget?.amount !== null
              ? `${invoice.budget?.amount}€ par 
            ${periodDic[invoice.budget?.period]}`
              : " -"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u> Nombre d'employés:</u> {invoice.numberOfStaff}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Boissons:</u>{" "}
            {invoice.beverage && invoice.beverage?.amount !== null
              ? `${invoice.beverage?.amount}/
            ${periodDic[invoice.beverage?.period]}/${invoice.beverage?.type}`
              : " -"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Snacks salés:</u>{" "}
            {invoice.salty && invoice.salty?.amount !== null
              ? `${invoice.salty?.amount}/
            ${periodDic[invoice.salty?.period]}/${invoice.salty?.type}`
              : " -"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Snacks sucrés:</u>{" "}
            {invoice.sweet && invoice.sweet?.amount !== null
              ? `${invoice.sweet?.amount}/
            ${periodDic[invoice.sweet?.period]}/${invoice.sweet?.type}`
              : " -"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Café:</u>{" "}
            {invoice.coffe && invoice.coffe?.amount !== null
              ? `${invoice.coffe?.amount}/
            ${periodDic[invoice.coffe?.period]}/${invoice.coffe?.type}`
              : " -"}
          </p>
          <p style={{ fontSize: "smaller" }}>
            <u>Contractualisation:</u>
            {invoice.isContractualised ? " Oui" : " Non"}
          </p>
        </div>
      </div>
    </div>
  );
}

export function ColumnOffer(props: any) {
  const { selectedProducts } = props;

  const {
    isContractualised,
    contractDuration,
    budget,
    numberOfStaff,
    createdWithClient,
  } = props.invoice;

  let totalPurchase = 0;
  let totalPrice = 0;

  let purchaseBeverage = 0;
  let profitBeverage = 0;
  let countBeverage = 0;

  let purchaseSalty = 0;
  let profitSalty = 0;
  let countSalty = 0;

  let purchaseSweet = 0;
  let profitSweet = 0;
  let countSweet = 0;

  let purchaseCoffe = 0;
  let profitCoffe = 0;
  let countCoffe = 0;

  let purchaseTea = 0;
  let profitTea = 0;
  let countTea = 0;

  let purchaseMaintenance = 0;
  let profitMaintenance = 0;
  let countMaintenance = 0;

  let purchaseFruit = 0;
  let profitFruit = 0;
  let countFruit = 0;

  let purchaseOther = 0;
  let profitOther = 0;
  let countOther = 0;

  (selectedProducts || []).map((p: Product) => {
    totalPrice += p.finalPrice || 0;
    totalPurchase += p.packPurchasePrice * p.quantity;
    if (
      p.category === "beverage" ||
      p.category === "soda" ||
      p.category === "water" ||
      p.category === "juice"
    ) {
      profitBeverage += p.finalPrice;
      purchaseBeverage += p.packPurchasePrice * p.quantity;
      countBeverage += p.currentQuantityOfUnit;
    } else if (p.category === "salty") {
      profitSalty += p.finalPrice;
      purchaseSalty += p.packPurchasePrice * p.quantity;
      countSalty += p.currentQuantityOfUnit;
    } else if (p.category === "sweet") {
      profitSweet += p.finalPrice;
      purchaseSweet += p.packPurchasePrice * p.quantity;
      countSweet += p.currentQuantityOfUnit;
    } else if (p.category === "coffe") {
      profitCoffe += p.finalPrice;
      purchaseCoffe += p.packPurchasePrice * p.quantity;
      countCoffe += p.currentQuantityOfUnit;
    } else if (p.category === "tea") {
      profitTea += p.finalPrice;
      purchaseTea += p.packPurchasePrice * p.quantity;
      countTea += p.currentQuantityOfUnit;
    } else if (p.category === "hygiene") {
      profitMaintenance += p.finalPrice;
      purchaseMaintenance += p.packPurchasePrice * p.quantity;
      countMaintenance += p.currentQuantityOfUnit;
    } else if (p.category === "fruit") {
      profitFruit += p.finalPrice;
      purchaseFruit += p.packPurchasePrice * p.quantity;
      countFruit += p.currentQuantityOfUnit;
    } else {
      profitOther += p.finalPrice;
      purchaseOther += p.packPurchasePrice * p.quantity;
      countOther += p.currentQuantityOfUnit;
    }
  });

  const monthlyBudget = getMonthlyBudget(budget);
  const isBudgetOver = monthlyBudget && monthlyBudget < totalPrice;
  return (
    <div className="column-offer">
      <div>
        <h3>Offre Globale</h3>

        <div className="div-offer">
          <h4 style={isBudgetOver ? { color: "red" } : {}}>
            Chiffre d'affaires
          </h4>
          <h4
            style={
              isBudgetOver
                ? { color: "red", whiteSpace: "nowrap" }
                : { whiteSpace: "nowrap" }
            }
          >
            {displayNumber(totalPrice)} €
          </h4>
        </div>
        {isContractualised ? (
          <div className="div-offer">
            <h4>Chiffre d'affaires contrat</h4>
            <h4 style={{ whiteSpace: "nowrap" }}>
              {displayNumber(totalPrice * contractDuration)} €
            </h4>
          </div>
        ) : (
          ""
        )}

        {!createdWithClient ? (
          <div className="div-offer">
            <h4>Marge globale</h4>
            <h4 style={{ whiteSpace: "nowrap" }}>
              {displayNumber(
                getMargin({ purchasePrice: totalPurchase, price: totalPrice })
              )}
              %
            </h4>
          </div>
        ) : (
          ""
        )}
        {!createdWithClient ? (
          <div className="div-offer">
            <h4>Cash marge</h4>
            <h4 style={{ whiteSpace: "nowrap" }}>
              {displayNumber(totalPrice - totalPurchase)} €
            </h4>
          </div>
        ) : (
          ""
        )}
        {isContractualised && !createdWithClient ? (
          <div className="div-offer">
            <h4>Cash marge contrat</h4>
            <h4 style={{ whiteSpace: "nowrap" }}>
              {displayNumber((totalPrice - totalPurchase) * contractDuration)} €
            </h4>
          </div>
        ) : (
          ""
        )}
        <div className="div-offer">
          <h4>Tarif / jour / employé</h4>
          <h4 style={{ whiteSpace: "nowrap" }}>
            {numberOfStaff
              ? formatNumber(
                  getDailyBudgetPerEmployee({
                    budget: totalPrice,
                    numberOfStaff,
                  })
                ) + " €"
              : "-"}
          </h4>
        </div>
        <div className="div-offer">
          <h4># d'articles / jour / collab </h4>
          <h4 style={{ whiteSpace: "nowrap" }}>
            {numberOfStaff
              ? formatNumber(
                  selectedProducts.reduce(
                    (prev: number, curr: any) =>
                      prev + curr.currentQuantityOfUnit,
                    0
                  ) /
                    numberOfStaff /
                    21
                )
              : "-"}
          </h4>
        </div>
      </div>

      <div>
        <p style={{ fontSize: "smaller", textDecoration: "underline" }}>
          Pour aller plus loin
        </p>
        <TableGlobalOffer
          budget={budget}
          offer1={[
            {
              id: "# de Boisson",
              number: countBeverage,
            },
            {
              id: "Boisson / j / collab",
              number: numberOfStaff
                ? displayNumber(countBeverage / numberOfStaff / 21)
                : "-",
            },
            {
              id: "Tarifs des boissons",
              number: `${displayNumber(profitBeverage)} €`,
              value: profitBeverage,
            },
            {
              id: "Tarifs moyen des boissons",
              number: `${displayNumber(
                formatNumber(divideNumbers(profitBeverage, countBeverage))
              )} €`,
            },
            !createdWithClient
              ? {
                  id: "Marge moyenne des boissons",
                  number: `${displayNumber(
                    formatNumber(
                      getMargin({
                        purchasePrice: purchaseBeverage,
                        price: profitBeverage,
                      })
                    )
                  )} %`,
                }
              : {},
          ]}
          offer2={[
            {
              id: "# de snacks salés",
              number: countSalty,
            },
            {
              id: "Snacks salés / j / collab",
              number: numberOfStaff
                ? displayNumber(countSalty / numberOfStaff / 21)
                : "-",
            },
            {
              id: "Tarifs des snacks salés",
              number: `${displayNumber(profitSalty)} €`,
              value: profitSalty,
            },
            {
              id: "Tarifs moyen snacks salés",
              number: `${displayNumber(
                formatNumber(divideNumbers(profitSalty, countSalty))
              )} €`,
            },
            !createdWithClient
              ? {
                  id: "Marge moyenne snacks salés",
                  number: `${displayNumber(
                    getMargin({
                      purchasePrice: purchaseSalty,
                      price: profitSalty,
                    })
                  )} %`,
                }
              : {},
          ]}
          offer3={[
            {
              id: "# de snacks sucrés",
              number: countSweet,
            },
            {
              id: "Snacks sucrés / j / collab",
              number: numberOfStaff
                ? displayNumber(countSweet / numberOfStaff / 21)
                : "-",
            },
            {
              id: "Tarifs des snacks sucrés",
              number: `${displayNumber(profitSweet)} €`,
              value: profitSweet,
            },
            {
              id: "Tarifs moyen des snacks sucrés",
              number: `${displayNumber(
                formatNumber(divideNumbers(profitSweet, countSweet))
              )} €`,
            },
            !createdWithClient
              ? {
                  id: "Marge moyenne des snacks sucrés",
                  number: `${displayNumber(
                    getMargin({
                      purchasePrice: purchaseSweet,
                      price: profitSweet,
                    })
                  )} %`,
                }
              : {},
          ]}
          offer4={[
            {
              id: "# de Cafés",
              number: countCoffe,
            },
            {
              id: "Cafés / j / collab",
              number: numberOfStaff
                ? displayNumber(countCoffe / numberOfStaff / 21)
                : "-",
            },
            {
              id: "Tarifs des Cafés",
              number: `${displayNumber(profitCoffe)} €`,
              value: profitCoffe,
            },
            {
              id: "Tarifs moyen des Cafés",
              number: `${displayNumber(
                formatNumber(divideNumbers(profitCoffe, countCoffe))
              )} €`,
            },
            !createdWithClient
              ? {
                  id: "Marge moyenne des Cafés",
                  number: `${displayNumber(
                    getMargin({
                      purchasePrice: purchaseCoffe,
                      price: profitCoffe,
                    })
                  )} %`,
                }
              : {},
          ]}
          offer5={[
            {
              id: "# de thés et tisanes",
              number: countTea,
            },
            {
              id: "Thés et tisanes / j / collab",
              number: numberOfStaff
                ? displayNumber(countTea / numberOfStaff / 21)
                : "-",
            },
            {
              id: "Tarifs des thés et tisanes",
              number: `${displayNumber(profitTea)} €`,
              value: profitTea,
            },
            {
              id: "Tarifs moyen des thés et tisanes",
              number: `${displayNumber(
                formatNumber(divideNumbers(profitTea, countTea))
              )} €`,
            },
            !createdWithClient
              ? {
                  id: "Marge moyenne des thés et tisanes",
                  number: `${displayNumber(
                    getMargin({
                      purchasePrice: purchaseTea,
                      price: profitTea,
                    })
                  )} %`,
                }
              : {},
          ]}
          offer6={[
            {
              id: "# de produits d'entrerien",
              number: countMaintenance,
            },
            {
              id: "Produits d'entrerien / j / collab",
              number: numberOfStaff
                ? displayNumber(countMaintenance / numberOfStaff / 21)
                : "-",
            },
            {
              id: "Tarifs des produits d'entrerien",
              number: `${displayNumber(profitMaintenance)} €`,
              value: profitMaintenance,
            },
            {
              id: "Tarifs moyen des produits d'entrerien",
              number: `${displayNumber(
                formatNumber(divideNumbers(profitMaintenance, countMaintenance))
              )} €`,
            },
            !createdWithClient
              ? {
                  id: "Marge moyenne des produits d'entrerien",
                  number: `${displayNumber(
                    getMargin({
                      purchasePrice: purchaseMaintenance,
                      price: profitMaintenance,
                    })
                  )} %`,
                }
              : {},
          ]}
          offer7={[
            {
              id: "# de fruits",
              number: countFruit,
            },
            {
              id: "Fruits / j / collab",
              number: numberOfStaff
                ? displayNumber(countFruit / numberOfStaff / 21)
                : "-",
            },
            {
              id: "Tarifs des fruits",
              number: `${displayNumber(profitFruit)} €`,
              value: profitFruit,
            },
            {
              id: "Tarifs moyen des fruits",
              number: `${displayNumber(
                formatNumber(divideNumbers(profitFruit, purchaseFruit))
              )} €`,
            },
            !createdWithClient
              ? {
                  id: "Marge moyenne des fruits",
                  number: `${displayNumber(
                    getMargin({
                      purchasePrice: purchaseFruit,
                      price: profitFruit,
                    })
                  )} %`,
                }
              : {},
          ]}
          offer8={[
            {
              id: "# de autres",
              number: countOther,
            },
            {
              id: "Autres / j / collab",
              number: numberOfStaff
                ? displayNumber(countOther / numberOfStaff / 21)
                : "-",
            },
            {
              id: "Tarifs des autres",
              number: `${displayNumber(profitOther)} €`,
              value: profitOther,
            },
            {
              id: "Tarifs moyen des autres",
              number: `${displayNumber(
                formatNumber(divideNumbers(profitOther, countOther))
              )} €`,
            },
            !createdWithClient
              ? {
                  id: "Marge moyenne des autres",
                  number: `${displayNumber(
                    getMargin({
                      purchasePrice: purchaseOther,
                      price: profitOther,
                    })
                  )} %`,
                }
              : {},
          ]}
        ></TableGlobalOffer>
      </div>
    </div>
  );
}

export function ButtonAction(props: any) {
  const { invoice } = props;

  const duplicateInvoice = async (invoiceId: string) => {
    const { value } = await editNameSwal();
    if (value) {
      try {
        await api.post<[Invoice]>("/admin/invoice/duplicate", {
          invoiceId,
          name: value,
        });
        infoSwal("L'offre a bien été dupliquée");
      } catch (error) {
        errorSwal(error as string);
        console.error("Error fetching data: ", error);
      }
    }
  };

  const deleteInvoice = async (invoiceId: string) => {
    promptSwal({ title: "Voulez vous supprimer cette offre ?" }).then(
      async (result) => {
        if (result.isConfirmed) {
          try {
            await api.post<[Invoice]>("/admin/invoice/editStatus", {
              invoiceId,
              status: "deleted",
            });
            infoSwal("L'offre a bien été supprimé");
            navigate("/dashboard");
          } catch (error) {
            errorSwal(error as string);
            console.error("Error fetching data: ", error);
          }
        }
      }
    );
  };

  const validateInvoice = async (invoiceId: string) => {
    promptSwal({
      title: `Voulez vous valider cette offre ?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.post<[Invoice]>("/admin/invoice/editStatus", {
            invoiceId,
            status: "confirmed",
          });
          navigate("/dashboard");
          infoSwal(`L'offre a bien été validé`);
        } catch (error) {
          errorSwal(error as string);
          console.error("Error fetching data: ", error);
        }
      }
    });
  };

  const downloadPdf = async (invoiceId: string) => {
    try {
      const res = await api.get<any>("/admin/invoice/download", {
        params: { invoiceId },
        responseType: "blob",
      });
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      saveAs(pdfBlob, "download.pdf");
    } catch (error) {
      errorSwal(error as string);
      console.error("Error fetching data: ", error);
    }
  };

  const downloadExcel = async (products: Array<Product>) => {
    const categoryDictionnary = {
      fountain: "Fontaine",
      beverage: "Boisson",
      tea: "Thé / Tisane",
      furniture: "Art de la table / meuble",
      coffe: "Café",
      sweet: "Epicerie sucrée",
      salty: "Epicerie salée",
      fruit: "Fruit",
      hygiene: "Hygiène / entretien",
      guest: "Guest experience",
      shipping: "Livraison",
      other: "Autre",
    };
    let workbook = new ExcelJS.Workbook();

    let worksheet = workbook.addWorksheet("My Worksheet");

    worksheet.addRow([
      "",
      "Référence Produit",
      "Marque",
      "Nom",
      "Quantité Commande",
      "Tarif par acticle",
      "Reduction proposée",
      "Quantié d'unités	",
      "Tarif par ligne",
    ]);

    const grouped: any = {};

    for (const p of products) {
      let cat = "";
      if (
        p.category === "beverage" ||
        p.category === "soda" ||
        p.category === "water" ||
        p.category === "juice"
      ) {
        cat = "beverage";
      } else if (
        p.category === "salty" ||
        p.category === "sweet" ||
        p.category === "coffe" ||
        p.category === "tea" ||
        p.category === "hygiene" ||
        p.category === "fruit"
      ) {
        cat = p.category;
      } else {
        cat = "other";
      }
      if (grouped[cat]) {
        grouped[cat].push(p);
      } else {
        grouped[cat] = [p];
      }
    }

    let index = 2;
    for (const cat of [
      "beverage",
      "salty",
      "sweet",
      "coffe",
      "tea",
      "hygiene",
      "fruit",
      "other",
    ]) {
      if (grouped[cat]) {
        worksheet.addRows(
          grouped[cat]
            .sort((a: Product, b: Product) => a.name.localeCompare(b.name))
            .map((product: Product, i: number) => {
              return [
                i === 0
                  ? categoryDictionnary[cat as keyof typeof categoryDictionnary]
                  : "",
                product.reference,
                product?.brand || "-",
                product.name,
                Number(displayVal(product.quantity)),
                Number(displayVal(product.currentPackPrice)),
                Number(displayVal(product.discount) / 100),
                Number(displayVal(product.currentQuantityOfUnit)),
                Number(displayVal(product.finalPrice)),
              ];
            })
        );
        for (let i = 0; i < grouped[cat].length; i++) {
          worksheet.getCell("F" + (index + i)).numFmt = '"€"#,##0.00';
          worksheet.getCell("I" + (index + i)).numFmt = '"€"#,##0.00';
          worksheet.getCell("G" + (index + i)).numFmt = "0.00%";
          worksheet.getCell("J" + (index + i)).numFmt = "0.00%";
        }

        if (grouped[cat].length > 1) {
          worksheet.mergeCells(index, 1, index + grouped[cat].length - 1, 1);
        }
        index += grouped[cat].length;
      }
    }

    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = { horizontal: "center" };
      });
    });

    let buffer = await workbook.xlsx.writeBuffer();

    let blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Export.xlsx";
    link.click();
  };

  const navigate = useNavigate();
  return (
    <div className="div-button-action">
      <button
        onClick={() => downloadPdf(invoice._id)}
        className="button-action"
      >
        <FiDownload style={{ color: "blue" }}></FiDownload>
        Télécharger votre offre (PDF)
      </button>
      <button
        onClick={() => downloadExcel(invoice.products)}
        className="button-action"
      >
        <FiDownload style={{ color: "blue" }}></FiDownload>
        Télécharger votre offre (Excel)
      </button>
      <button
        className="button-action"
        onClick={() => {
          navigate("/search", { state: { invoice, editOnly: true } });
        }}
      >
        <FiEdit style={{ color: "red" }}></FiEdit>
        Modifier votre offre
      </button>
      <button
        className="button-action"
        onClick={() => validateInvoice(invoice._id)}
      >
        <FiCheck style={{ color: "green" }}></FiCheck>
        Valider votre offre
      </button>
      <button
        className="button-action"
        onClick={() => duplicateInvoice(invoice._id)}
      >
        <HiOutlineDocumentDuplicate></HiOutlineDocumentDuplicate>
        Dupliquer votre offre
      </button>
      <button
        className="button-action"
        onClick={() => deleteInvoice(invoice._id)}
      >
        <RiDeleteBin2Fill style={{ color: "red" }}></RiDeleteBin2Fill>
        Supprimer votre offre
      </button>
    </div>
  );
}

function TableGlobalOffer(props: any) {
  const monthlyBudget = getMonthlyBudget(props.budget);
  const getStyle = ({ index, offer }: { index: any; offer: any }) => {
    if (index !== 0) {
      return {};
    }
    if (monthlyBudget && offer[1].value && offer[1].value > monthlyBudget) {
      return {
        fontWeight: "600",
        textTransform: "uppercase",
        color: "red",
      };
    }
    return {
      fontWeight: "600",
      textTransform: "uppercase",
    };
  };
  return (
    <table style={{ width: "100%" }}>
      <tbody style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {props.offer1[0].number ? (
          <div>
            {props.offer1.map((item: any, index: any) => (
              <GlobalOfferLine
                style={getStyle({ index, offer: props.offer1 })}
                offer={item}
                index={index}
              ></GlobalOfferLine>
            ))}
          </div>
        ) : (
          ""
        )}

        {props.offer2[0].number ? (
          <div>
            {props.offer2.map((item: any, index: any) => (
              <GlobalOfferLine
                style={getStyle({ index, offer: props.offer2 })}
                offer={item}
                index={index}
              ></GlobalOfferLine>
            ))}
          </div>
        ) : (
          ""
        )}
        {props.offer3[0].number ? (
          <div>
            {props.offer3.map((item: any, index: any) => (
              <GlobalOfferLine
                style={getStyle({ index, offer: props.offer3 })}
                offer={item}
                index={index}
              ></GlobalOfferLine>
            ))}
          </div>
        ) : (
          ""
        )}

        {props.offer4[0].number ? (
          <div>
            {props.offer4.map((item: any, index: any) => (
              <GlobalOfferLine
                style={getStyle({ index, offer: props.offer4 })}
                offer={item}
                index={index}
              ></GlobalOfferLine>
            ))}
          </div>
        ) : (
          ""
        )}
        {props.offer5[0].number ? (
          <div>
            {props.offer5.map((item: any, index: any) => (
              <GlobalOfferLine
                style={getStyle({ index, offer: props.offer5 })}
                offer={item}
                index={index}
              ></GlobalOfferLine>
            ))}
          </div>
        ) : (
          ""
        )}

        {props.offer6[0].number ? (
          <div>
            {props.offer6.map((item: any, index: any) => (
              <GlobalOfferLine
                style={getStyle({ index, offer: props.offer6 })}
                offer={item}
                index={index}
              ></GlobalOfferLine>
            ))}
          </div>
        ) : (
          ""
        )}

        {props.offer7[0].number ? (
          <div>
            {props.offer7.map((item: any, index: any) => (
              <GlobalOfferLine
                style={getStyle({ index, offer: props.offer7 })}
                offer={item}
                index={index}
              ></GlobalOfferLine>
            ))}
          </div>
        ) : (
          ""
        )}

        {props.offer8[0].number ? (
          <div>
            {props.offer8.map((item: any, index: any) => (
              <GlobalOfferLine
                style={getStyle({ index, offer: props.offer8 })}
                offer={item}
                index={index}
              ></GlobalOfferLine>
            ))}
          </div>
        ) : (
          ""
        )}
      </tbody>
    </table>
  );
}

function TableProduct({
  products,
  createdWithClient,
}: {
  products: any;
  createdWithClient: boolean;
}) {
  const defaultCell = ({
    cell,
    column: { id },
  }: {
    cell: any;
    column: { id: string };
  }) => (
    <td
      style={{ textAlign: "center" }}
      className="item"
      {...cell.getCellProps()}
    >
      {id === "reference" ? cell.value : displayVal(cell.value)}
      {getValueLabel(id)}
    </td>
  );

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Référence Produit",
          accessor: "reference",
          width: 60,
          Cell: defaultCell,
        },
        {
          Header: "Marque",
          accessor: (product: any) => product?.brand || "-",
          width: 60,
          Cell: defaultCell,
        },
        {
          Header: "Produit Choisi",
          accessor: "name",
          width: 200,
          Cell: defaultCell,
        },

        {
          Header: "Quantité Commande",
          accessor: "quantity",
          width: 40,
          Cell: defaultCell,
        },

        {
          Header: "Tarif par acticle",
          accessor: "currentUnitPrice",
          width: 40,
          Cell: defaultCell,
        },
        {
          Header: "Reduction proposée",
          accessor: "discount",
          width: 40,
          Cell: defaultCell,
        },
        {
          Header: "Quantié d'unités",
          accessor: "currentQuantityOfUnit",
          width: 40,
          Cell: defaultCell,
        },
        {
          Header: "Tarif par ligne",
          accessor: "finalPrice",
          width: 40,
          Cell: defaultCell,
        },
        {
          Header: "Marge N-1",
          accessor: (product: any) => product?.lastPackPrice || "-",
          width: 40,
          Cell: defaultCell,
        },
        {
          Header: "Marge par ligne",
          accessor: "margin",
          width: 40,
          Cell: defaultCell,
        },
      ].filter((col: any) =>
        createdWithClient
          ? col.accessor !== "margin" && col.Header !== "Marge N-1"
          : true
      ),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: products });
  const productsSoted = rows.sort((a: any, b: any) =>
    a.original.brand.localeCompare(b.original.brand)
  );
  const beverages: any[] = [];
  const salty: any[] = [];
  const sweet: any[] = [];
  const coffe: any[] = [];
  const tea: any[] = [];
  const fountain: any[] = [];
  const shipping: any[] = [];
  const other: any[] = [];
  const fruits: any[] = [];

  productsSoted.map((row: any) => {
    if (
      row.original.category === "beverage" ||
      row.original.category === "soda" ||
      row.original.category === "water" ||
      row.original.category === "juice"
    ) {
      beverages.push(row);
    } else if (row.original.category === "salty") {
      salty.push(row);
    } else if (row.original.category === "sweet") {
      sweet.push(row);
    } else if (row.original.category === "coffe") {
      coffe.push(row);
    } else if (row.original.category === "tea") {
      tea.push(row);
    } else if (row.original.category === "fountain") {
      fountain.push(row);
    } else if (row.original.category === "shipping") {
      shipping.push(row);
    } else if (row.original.category === "fruit") {
      fruits.push(row);
    } else {
      other.push(row);
    }
  });

  return (
    <div
      style={{
        height: "90vh",
        overflowY: "scroll",
        overflowX: "hidden",
        paddingRight: "20px",
      }}
    >
      <table {...getTableProps()} className="table-product">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  className="th-view"
                  {...column.getHeaderProps()}
                  style={{ width: column.width }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {beverages.length ? (
          <>
            <td>
              <p className="table-category">VOS BOISSONS</p>
            </td>
            <tbody {...getTableBodyProps()}>
              {beverages.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => cell.render("Cell"))}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}

        {salty.length ? (
          <>
            <p className="table-category">VOS SNACKS SALES</p>
            <tbody {...getTableBodyProps()}>
              {salty.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => cell.render("Cell"))}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {sweet.length ? (
          <>
            <p className="table-category">VOS SNACKS SUCRES</p>
            <tbody {...getTableBodyProps()}>
              {sweet.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => cell.render("Cell"))}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {coffe.length ? (
          <>
            <p className="table-category">VOS SOLUTIONS CAFE</p>
            <tbody {...getTableBodyProps()}>
              {coffe.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => cell.render("Cell"))}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {tea.length ? (
          <>
            <p className="table-category">THE ET TISANE</p>
            <tbody {...getTableBodyProps()}>
              {tea.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => cell.render("Cell"))}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {fruits.length ? (
          <>
            <p className="table-category">Fruits</p>
            <tbody {...getTableBodyProps()}>
              {fruits.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => cell.render("Cell"))}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {fountain.length ? (
          <>
            <p className="table-category">VOTRE SOLUTIONS FONTAINE</p>
            <tbody {...getTableBodyProps()}>
              {fountain.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => cell.render("Cell"))}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {shipping.length ? (
          <>
            <td>
              <p className="table-category">VOS LIVRAISONS ET AGENCEMENT</p>
            </td>
            <tbody {...getTableBodyProps()}>
              {shipping.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => cell.render("Cell"))}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
        {other.length ? (
          <>
            <td>
              <p className="table-category">AUTRES</p>
            </td>
            <tbody {...getTableBodyProps()}>
              {other.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => cell.render("Cell"))}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          ""
        )}
      </table>
    </div>
  );
}

function GlobalOfferLine(props: any) {
  const { offer, index } = props;
  return (
    <tr className="tr-offer" style={props.style}>
      <td style={index !== 0 ? { fontStyle: "italic" } : {}}>{offer.id}</td>
      <td style={{ whiteSpace: "nowrap" }}>{displayVal(offer.number)}</td>
    </tr>
  );
}
