import "./App.css";
import React from "react";
import FinalPage from "./pages/FinalPage";
import DashBoard from "./pages/DashBoard";
import SearchPage from "./pages/SearchPage";
import ViewDevis from "./pages/ViewDevis";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthContainer from "./auth/AuthContainer";
import Login from "./pages/Login";
import CreateDevis from "./pages/CreateDevis";

const routes = [
  {
    label: "Connexion",
    path: "/",
    requireAuth: false,
    element: <Login />,
  },
  {
    label: "DashBoard",
    path: "/dashboard",
    requireAuth: true,
    element: <DashBoard />,
  },
  {
    label: "ViewDevis",
    path: "/view",
    requireAuth: true,
    element: <ViewDevis />,
  },
  {
    label: "CreateDevis",
    path: "/create",
    requireAuth: true,
    element: <CreateDevis />,
  },
  {
    label: "SearchPage",
    path: "/search",
    requireAuth: true,
    element: <SearchPage />,
  },
  {
    label: "FinalPage",
    path: "/final",
    requireAuth: true,
    element: <FinalPage />,
  },
];

const queryClient = new QueryClient();

function App() {
  // const { currentUser } = useAuthStore();
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div
          className="d-flex flex-column flex-root"
          style={{ height: "100%" }}
        >
          <div className="page d-flex flex-row flex-column-fluid">
            <div className="wrapper d-flex flex-column flex-row-fluid">
              <Routes>
                {routes.map((route) => {
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        <AuthContainer
                          name={route.label}
                          requireAuth={route.requireAuth}
                        >
                          {route.element}
                        </AuthContainer>
                      }
                    />
                  );
                })}
              </Routes>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
