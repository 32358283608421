import { useState, useEffect } from "react";
import { ButtonOrange } from "./Components";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../auth/api";
import { Client, Invoice } from "../types";
import { errorSwal } from "../swal";
import { useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

export default function NewDevis() {
  return (
    <>
      <div className="nv-devis">
        <NewHeader></NewHeader>
        <FormNewDevis></FormNewDevis>
      </div>
    </>
  );
}

function NewHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "10px" }}>
          <button
            className="icon-button"
            onClick={() =>
              location?.state?.editOnly
                ? navigate("/view", {
                    state: { invoice: location?.state?.invoice },
                  })
                : navigate("/dashboard")
            }
          >
            <FaArrowLeft
              style={{ marginTop: "5px" }}
              size={30}
              className="icon-hover"
            ></FaArrowLeft>
          </button>
        </div>
        <div>
          <h1>
            {location?.state?.editOnly ? "Modifier l'offre" : "Nouvelle offre"}
          </h1>
        </div>
      </div>
      <p>
        Veuillez remplir les informations relatives à votre offre et contact.
        Vous pourrez revenir en arrière à tout moment afin de modifier cette
        offre.
      </p>
    </div>
  );
}

function FormNewDevis() {
  const location = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: location?.state?.invoice || {
      numberOfStaff: 0,
      budget: {
        amount: undefined,
        period: "year",
      },
      beverage: {
        amount: undefined,
        period: "day",
        type: "employee",
      },
      salty: {
        amount: undefined,
        period: "day",
        type: "employee",
      },
      sweet: {
        amount: undefined,
        period: "day",
        type: "employee",
      },
      coffe: {
        amount: undefined,
        period: "day",
        type: "employee",
      },
      fruit: {
        amount: undefined,
        period: "day",
        type: "employee",
      },
      tea: {
        amount: undefined,
        period: "day",
        type: "employee",
      },
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<[Client]>();
  const [clientCode, setClientCode] = useState("");
  const [isSet, setIsSet] = useState(false);

  const autoFillClient = (code: string) => {
    const client = (clients || []).find((c) => c.code === code);
    if (client) {
      if (client.company) {
        setValue("company", client.company);
      }
      if (client.siret) {
        setValue("siret", client.siret);
      }
      if (client.address) {
        setValue("billingAdress", client.address);
      }
      if (client.address) {
        setValue("shippingAdress", client.address);
      }
      if (client.phone) {
        setValue("contact.phone", client.phone);
      }
      if (client.email) {
        setValue("contact.email", client.email);
      }
    }
  };

  const autoFillClientId = ({
    id,
    clients,
  }: {
    id: string;
    clients: Array<Client>;
  }) => {
    const client = (clients || []).find((c) => c._id === id);
    if (client) {
      setValue("company", client.company);
      setValue("siret", client.siret);
      setValue("billingAdress", location?.state?.invoice.billingAdress);
      setValue("shippingAdress", location?.state?.invoice.shippingAdress);
      setValue("contact.phone", location?.state?.invoice?.contact?.phone);
      setValue("contact.email", location?.state?.invoice?.contact?.email);
      setClientCode(client.code);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      if (location?.state?.editOnly) {
        const res = await api.post<Invoice>("/admin/invoice/edit", {
          ...data,
          clientCode,
        });
        navigate("/view", { state: { invoice: res.data } });
      } else {
        const res = await api.post<Invoice>("/admin/invoice/create", {
          ...data,
          clientCode,
        });
        navigate("/search", { state: { invoice: res.data } });
      }
    } catch (e) {
      errorSwal(e as string);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clients = await api.get<[Client]>("/admin/client/get");
        setClients(clients.data);
        autoFillClient(getValues("code"));
        if (!isSet) {
          autoFillClientId({
            id: location?.state?.invoice?.clientId || "",
            clients: clients.data,
          });
          setIsSet(true);
        }
      } catch (error) {
        errorSwal(
          "Une erreur s'est produite lors de la récupération des clients"
        );
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  // const { register, handleSubmit } = useForm({
  //   defaultValues: {
  //     profile: {
  //       firstName: "Default First Name",
  //       lastName: "Default Last Name",
  //     },
  //   },
  // });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form">
      <div className="organisation" style={{ flex: 1 }}>
        <div className="input-double">
          <label className="label-input">
            Organisation *
            <input
              className="input-form-double"
              type="text"
              {...register("company")}
            ></input>
          </label>
          <label className="label-input">
            Code client
            <input
              className="input-form-double"
              type="text"
              name="clientCode"
              value={clientCode}
              onInput={(e: any) => {
                if (e.target.value !== clientCode) {
                  setClientCode(e.target.value);
                  autoFillClient(e.target.value);
                }
              }}
            ></input>
          </label>
        </div>

        <label>
          Nom de l'offre *
          <input
            className="input-form"
            type="text"
            {...register("name")}
          ></input>
        </label>

        <div className="checkbox" style={{ verticalAlign: "center" }}>
          <input type="checkbox" {...register("createdWithClient")}></input>
          <label style={{ marginTop: "0px" }}>
            Création de l'offre avec un client
          </label>
        </div>
      </div>

      <div className="contact" style={{ flex: 1 }}>
        <label>
          Nom du contact
          <input
            className="input-form"
            type="text"
            {...register("contact.lastName")}
          ></input>
        </label>

        <label>
          Prénom du contact
          <input
            className="input-form"
            type="text"
            {...register("contact.firstName")}
          ></input>
        </label>

        <label>
          Poste du contact
          <input
            className="input-form"
            type="text"
            {...register("contact.job")}
          ></input>
        </label>

        <label>
          Téléphone du contact
          <input
            className="input-form"
            type="text"
            {...register("contact.phone")}
          ></input>
        </label>

        <label>
          Mail du contact
          <input
            className="input-form"
            type="text"
            {...register("contact.email")}
          ></input>
        </label>

        <div style={{ display: "flex", gap: "20px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <label style={{ fontSize: "smaller" }}>Type de contrat</label>

              <select className="select-time" {...register("contractType")}>
                <option value="">-</option>
                <option value="fidelity">Fidélité</option>
                <option value="basket">Panier</option>
                <option value="fruits">Fruits</option>
                <option value="beverage">Boissons chaudes</option>
              </select>
            </div>
          </div>
          <div>
            <label style={{ fontSize: "smaller" }}>
              Nombre de livraison par mois
            </label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                className="input-form"
                style={{ width: "100%" }}
                type="text"
                {...register("shippingPerMonth")}
              ></input>
            </div>
          </div>
        </div>
        <p style={{ fontSize: "smaller" }}>Contractualisation</p>
        <div style={{ display: "flex", gap: "20px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              style={{ marginRight: "10px" }}
              {...register("isContractualised")}
            ></input>
            <label style={{ marginTop: "0px" }}>Oui</label>
          </div>
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <input
              className="input-form"
              style={{ width: "70%" }}
              type="text"
              {...register("contractDuration")}
            ></input>
            <p>Mois</p>
          </div>
        </div>
      </div>

      <div className="employee" style={{ flex: 1 }}>
        <label>
          Nombre d'employés
          <input
            className="input-form"
            type="text"
            {...register("numberOfStaff")}
          ></input>
        </label>

        <div className="budget">
          <label className="label-input">
            Budget
            <input
              className="input-form"
              type="text"
              {...register("budget.amount")}
            ></input>
          </label>

          <select className="select-budget-type" {...register("budget.period")}>
            <option value="year">An</option>
            <option value="month">Mois</option>
            <option value="week">Semaine</option>
            <option value="day">Jour</option>
          </select>
        </div>
        <p className="text-underline">Demandes particulières :</p>
        <SelectLines
          name="Boissons"
          refAmount={"beverage.amount"}
          refPeriod={"beverage.period"}
          refType={"beverage.type"}
          register={register}
        ></SelectLines>
        <SelectLines
          name="snacks salés"
          refAmount={"salty.amount"}
          refPeriod={"salty.period"}
          refType={"salty.type"}
          register={register}
        ></SelectLines>
        <SelectLines
          name="snacks sucré"
          refAmount={"sweet.amount"}
          refPeriod={"sweet.period"}
          refType={"sweet.type"}
          register={register}
        ></SelectLines>
        <SelectLines
          name="Café"
          refAmount={"coffe.amount"}
          refPeriod={"coffe.period"}
          refType={"coffe.type"}
          register={register}
        ></SelectLines>
        <SelectLines
          name="Fruit"
          refAmount={"fruit.amount"}
          refPeriod={"fruit.period"}
          refType={"fruit.type"}
          register={register}
        ></SelectLines>
        <SelectLines
          name="Thé et tisane"
          refAmount={"tea.amount"}
          refPeriod={"tea.period"}
          refType={"tea.type"}
          register={register}
        ></SelectLines>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "30px",
          }}
        >
          <ButtonOrange
            isLoading={isLoading}
            buttonText={
              location?.state?.editOnly ? "Confirmer" : "Prochaine étape"
            }
          ></ButtonOrange>
        </div>
      </div>
    </form>
  );
}

function SelectLines(props: any) {
  const { refAmount, refPeriod, refType, register } = props;

  return (
    <div className="select-lines">
      <label className="label-input">
        {props.name}
        <input
          className="input-form"
          type="text"
          {...register(refAmount)}
        ></input>
      </label>

      <select className="select-time" {...register(refPeriod)}>
        <option value="month">Mois</option>
        <option value="week">Semaine</option>
        <option value="day">Jour</option>
      </select>
      <select className="select-by" {...register(refType)}>
        <option value="total">Total</option>
        <option value="employee">employé</option>
      </select>
    </div>
  );
}
