import { infoSwal, errorSwal, promptSwal } from "../swal";
import api from "../auth/api";
import { Product } from "../types";

const promptSaveInvoice = async ({
  invoiceId,
  selectedProducts,
}: {
  invoiceId: string;
  selectedProducts: Array<Product>;
}) => {
  const result = await promptSwal({
    title: `Attention les modifications n'ont pas été enregistrée !`,
    text: "Voulez-vous les sauvegarder ?",
    cancelButtonText: "Non",
  });
  if (result.isConfirmed) {
    try {
      await api.post("/admin/invoice/editProducts", {
        products: selectedProducts,
        invoiceId,
      });
      infoSwal("L'offre a bien été sauvegardée");
    } catch (error) {
      errorSwal(error as string);
      console.error("Error fetching data: ", error);
    }
  }
};

export default promptSaveInvoice;
