import { useState, useEffect, useRef } from "react";
import { ColumnOffer } from "./ViewDevis";
import { useTable, useSortBy } from "react-table";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { RiDeleteBin2Fill } from "react-icons/ri";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router";
import { Product } from "../types";
import api from "../auth/api";
import allocateQuantity from "../allocateQuantity";
import { updateProduct, getMonthlyBudget } from "../utils";
import { useLocation } from "react-router-dom";
import { errorSwal } from "../swal";
import SearchBlock from "./SearchBlock";
export default function SearchPage() {
  const location = useLocation();

  const [products, setProducts] = useState<Array<Product>>([]);

  const [selectedProducts, setSelectedProducts] = useState<Array<Product>>(
    location?.state?.invoice?.products || []
  );
  const [filterCategory, setFilterCategory] = useState<string | undefined>();
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsRequest = await api.get<[Product]>("/product/get");
        const selectedProductIds = selectedProducts.map((p: Product) => p._id);
        setProducts(
          (productsRequest.data || [])
            .map((p: Product) => {
              if (selectedProductIds.includes(p._id)) {
                const selectedProductTemp = selectedProducts.find(
                  ({ _id }: { _id: string }) => _id === p._id
                );
                p.isSelected = true;
                p.quantity = selectedProductTemp?.quantity || 0;
                p.currentQuantityOfUnit =
                  selectedProductTemp?.currentQuantityOfUnit || 0;
                p.currentUnitPrice = selectedProductTemp?.currentUnitPrice || 0;
                p.currentPackPrice = selectedProductTemp?.currentPackPrice || 0;
                updateProduct({ p, fieldChanged: "none" });
              } else {
                p.quantity = 1;
                p.currentQuantityOfUnit = p.quantityOfUnit;
                updateProduct({ p });
                p.quantity = 0;
              }

              return p;
            })
            .sort((p1: Product, p2: Product) => p1.name.localeCompare(p2.name))
        );
      } catch (error) {
        errorSwal(error as string);
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="div-search-page">
      <SearchBlock
        selectedProducts={selectedProducts}
        setFilterCategory={setFilterCategory}
        filterCategory={filterCategory}
        search={search}
        setSearch={setSearch}
      ></SearchBlock>
      <TableSearch
        products={products}
        displayedProducts={products.filter(
          (product: Product) =>
            (search !== undefined
              ? new RegExp(search, "i").test(product.searchString)
              : true) &&
            (filterCategory
              ? product.category === filterCategory ||
                (filterCategory === "beverage" &&
                  ["juice", "soda", "water"].includes(product.category)) ||
                (["guest", "coffe"].includes(filterCategory) &&
                  ["17288", "17287", "16330"].includes(product.reference))
              : true)
        )}
        setSelectedProducts={setSelectedProducts}
        setProducts={setProducts}
        selectedProducts={selectedProducts}
      ></TableSearch>
      <SideSearchPage
        invoice={location.state.invoice}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        setProducts={setProducts}
        products={products}
      ></SideSearchPage>
    </div>
  );
}

function SideSearchPage(props: any) {
  const location = useLocation();
  const {
    selectedProducts,
    setSelectedProducts,
    invoice,
    setProducts,
    products,
  } = props;
  const navigate = useNavigate();
  return (
    <div className="div-side-search-page">
      <button
        onClick={() => {
          const newProducts = allocateQuantity({
            products: selectedProducts,
            budget: getMonthlyBudget(props.invoice.budget),
          });
          if (newProducts) {
            setSelectedProducts(
              newProducts.map((p: Product) => {
                updateProduct({ p, fieldChanged: "quantity" });
                return p;
              })
            );
            const newProductIds = newProducts.map((p: Product) => p._id);
            setProducts((products: Array<Product>) =>
              products.map((p: Product) => {
                if (newProductIds.includes(p._id)) {
                  const productQuantity = newProducts.find(
                    (pq: Product) => pq._id === p._id
                  );

                  p.quantity = productQuantity.quantity;
                  updateProduct({ p: p, fieldChanged: "quantity" });
                  return p;
                }
                return p;
              })
            );
          }
        }}
        className="button-orange"
      >
        Générer des quantités
      </button>
      <h3 style={{ margin: 0 }}>Votre Séléction</h3>
      <YourSelection
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        setProducts={setProducts}
        products={products}
      ></YourSelection>
      <ColumnOffer
        invoice={location?.state?.invoice}
        selectedProducts={selectedProducts}
      ></ColumnOffer>
      <button
        onClick={() => {
          navigate("/final", {
            state: {
              selectedProducts,
              invoice,
              editOnly: location?.state?.editOnly,
            },
          });
        }}
        className="button-orange"
      >
        Prochaine étape
      </button>
    </div>
  );
}

function YourSelection(props: any) {
  const { selectedProducts, setSelectedProducts, setProducts, products } =
    props;

  const beverages: Product[] = [];
  const salty: Product[] = [];
  const sweet: Product[] = [];
  const coffe: Product[] = [];
  const tea: Product[] = [];
  const fruits: Product[] = [];
  const hygiene: Product[] = [];
  const other: Product[] = [];
  selectedProducts.map((p: Product) => {
    if (
      p.category === "beverage" ||
      p.category === "soda" ||
      p.category === "water" ||
      p.category === "juice"
    ) {
      beverages.push(p);
    } else if (p.category === "salty") {
      salty.push(p);
    } else if (p.category === "sweet") {
      sweet.push(p);
    } else if (p.category === "coffe") {
      coffe.push(p);
    } else if (p.category === "tea") {
      tea.push(p);
    } else if (p.category === "fruit") {
      fruits.push(p);
    } else if (p.category === "hygiene") {
      hygiene.push(p);
    } else {
      other.push(p);
    }
  });

  return (
    <div className="your-selection">
      {beverages.length > 0 ? (
        <>
          <h3>Boisson</h3>
          {beverages.map((product: Product) => (
            <SelectionProduct
              key={product._id}
              product={product}
              setSelectedProducts={setSelectedProducts}
              setProducts={setProducts}
              products={products}
            />
          ))}
        </>
      ) : (
        ""
      )}
      {salty.length ? (
        <>
          <h3>Snacks salés</h3>
          {salty.map((product: Product) => (
            <SelectionProduct
              key={product._id}
              product={product}
              setSelectedProducts={setSelectedProducts}
              setProducts={setProducts}
              products={products}
            />
          ))}
        </>
      ) : (
        ""
      )}

      {sweet.length ? (
        <>
          <h3>Snack sucrés</h3>
          {sweet.map((product: Product) => (
            <SelectionProduct
              key={product._id}
              product={product}
              setSelectedProducts={setSelectedProducts}
              setProducts={setProducts}
              products={products}
            />
          ))}
        </>
      ) : (
        ""
      )}
      {coffe.length ? (
        <>
          <h3>Cafés</h3>
          {coffe.map((product: Product) => (
            <SelectionProduct
              key={product._id}
              product={product}
              setSelectedProducts={setSelectedProducts}
              setProducts={setProducts}
              products={products}
            />
          ))}
        </>
      ) : (
        ""
      )}

      {tea.length ? (
        <>
          <h3>Thés et tisanes</h3>
          {tea.map((product: Product) => (
            <SelectionProduct
              key={product._id}
              product={product}
              setSelectedProducts={setSelectedProducts}
              setProducts={setProducts}
              products={products}
            />
          ))}
        </>
      ) : (
        ""
      )}

      {fruits.length ? (
        <>
          <h3>Fruits</h3>
          {fruits.map((product: Product) => (
            <SelectionProduct
              key={product._id}
              product={product}
              setSelectedProducts={setSelectedProducts}
              setProducts={setProducts}
              products={products}
            />
          ))}
        </>
      ) : (
        ""
      )}

      {hygiene.length ? (
        <>
          <h3>Produits d'entretien</h3>
          {hygiene.map((product: Product) => (
            <SelectionProduct
              key={product._id}
              product={product}
              setSelectedProducts={setSelectedProducts}
              setProducts={setProducts}
              products={products}
            />
          ))}
        </>
      ) : (
        ""
      )}
      {other.length ? (
        <>
          <h3>Autres</h3>
          {other.map((product: Product) => (
            <SelectionProduct
              key={product._id}
              product={product}
              setSelectedProducts={setSelectedProducts}
              setProducts={setProducts}
              products={products}
            />
          ))}
        </>
      ) : (
        ""
      )}
    </div>
  );
}

function SelectionProduct(props: any) {
  const { product, setSelectedProducts, setProducts, products } = props;
  return (
    <div key={product._id} className="div-selection-line">
      <button
        className="icon-button"
        onClick={() => {
          removeSelectedProduct({
            product,
            setSelectedProducts,
            setProducts,
            products,
          });
        }}
      >
        <RxCross1
          className="icon-hover"
          size={12}
          style={{ color: "red" }}
        ></RxCross1>
      </button>
      <p style={{ fontSize: "smaller", margin: "2px" }}>{product.name}</p>
    </div>
  );
}

function TableSearch(props: any) {
  const { setProducts, setSelectedProducts, products, displayedProducts } =
    props;

  const columns = React.useMemo(
    () => [
      {
        Header: "Référence",
        accessor: "a",
        width: 60,
      },
      {
        Header: "Marque",
        accessor: "b",
        width: 60,
      },
      {
        Header: "Désignation du produit",
        accessor: "c",
        width: 200,
      },

      {
        Header: "Quantité",
        accessor: "d",
        width: 10,
      },

      {
        Header: "Tarif par article",
        accessor: "e",
        width: 30,
      },

      {
        Header: "Quantités d'Unités",
        accessor: "f",
        width: 30,
      },
      {
        Header: "",
        accessor: "g",
        width: 10,
      },
    ],
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: displayedProducts }, useSortBy);

  return (
    <div style={{ marginTop: "20px", flex: "18" }}>
      <h3 style={{ color: "#C4664A", marginLeft: "10px" }}>Selectionner</h3>
      <div
        className="scrollbar"
        style={{
          height: "90vh",
          overflowY: "scroll",
        }}
      >
        <table {...getTableProps()} className="table-search">
          <thead style={{ zIndex: 100 }}>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th style={{ width: column.width }} className="th-search">
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <MdArrowDropDown />
                      ) : (
                        <MdArrowDropUp />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ overflowY: "scroll" }}>
            {rows.map((row: any, i: number) => {
              prepareRow(row);
              return (
                <SearchProductCard
                  product={row.original}
                  setProducts={setProducts}
                  setSelectedProducts={setSelectedProducts}
                  products={products}
                ></SearchProductCard>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const removeSelectedProduct = ({
  product,
  setProducts,
  setSelectedProducts,
  products,
}: any) => {
  setSelectedProducts((curr: Array<Product>) => {
    return [...curr.filter((p: Product) => p._id !== product._id)];
  });

  product.quantity = 1;
  product.currentQuantityOfUnit = product.quantityOfUnit;
  updateProduct({ p: product });
  product.quantity = 0;
  product.isSelected = false;

  setProducts((curr: Array<Product>) => {
    return [...curr];
  });
};

const SearchProductCard = ({
  product,
  setProducts,
  setSelectedProducts,
  products,
}: {
  product: Product;
  setProducts: Function;
  setSelectedProducts: Function;
  products: Array<Product>;
}) => {
  const { isSelected } = product;

  let backgroundColor = "";

  if (isSelected) {
    backgroundColor = "#eec69f";
  } else if (product.origin === "excel") {
    backgroundColor = "#f1d9d9";
  }
  return (
    <tr
      style={{ width: "100%" }}
      key={product._id}
      onClick={() => {
        if (isSelected) {
          removeSelectedProduct({
            product,
            setSelectedProducts,
            setProducts,
            products,
          });
        } else {
          product.quantity = 1;
          product.isSelected = true;
          updateProduct({ p: product });

          setSelectedProducts((curr: Array<Product>) => {
            return [...curr, product];
          });
          setProducts((curr: Array<Product>) => {
            return [...curr];
          });
        }
      }}
    >
      <td
        style={{
          textAlign: "center",
          fontSize: "smaller",
          backgroundColor: backgroundColor,
        }}
        className="item-line"
      >
        {product.reference}
      </td>
      <td
        style={{
          textAlign: "center",
          fontSize: "smaller",
          backgroundColor: backgroundColor,
        }}
        className="item-line"
      >
        {product.brand || "-"}
      </td>
      <td
        style={{
          textAlign: "center",
          fontSize: "smaller",
          backgroundColor: backgroundColor,
        }}
        className="item-line"
      >
        {product.name}
      </td>
      {isSelected ? (
        <td style={{ textAlign: "center" }} className="input-item">
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              className="input-table"
              type="text"
              style={{ textAlign: "center" }}
              value={product.quantity}
              onClick={(e) => e.stopPropagation()}
              onInput={(e: any) => {
                setProducts((curr: Array<Product>) => {
                  product.quantity = Number(e.target.value);
                  return [...curr];
                });
                setSelectedProducts((curr: Array<Product>) => {
                  product.quantity = Number(e.target.value);
                  return [...curr];
                });
              }}
              onBlur={(e: any) => {
                setProducts((curr: Array<Product>) => {
                  product.quantity = Number(e.target.value);
                  updateProduct({
                    p: product,
                    fieldChanged: "quantity",
                  });
                  return [...curr];
                });

                setSelectedProducts((curr: Array<Product>) => {
                  product.quantity = Number(e.target.value);
                  updateProduct({
                    p: product,
                    fieldChanged: "quantity",
                  });
                  return [...curr];
                });
              }}
            />
          </div>
        </td>
      ) : (
        <td
          style={{
            textAlign: "center",
            fontSize: "smaller",
            backgroundColor: backgroundColor,
          }}
          className="item-line"
        >
          {product.quantity}
        </td>
      )}
      <td
        style={{
          textAlign: "center",
          fontSize: "smaller",
          backgroundColor: backgroundColor,
        }}
        className="item-line"
      >
        {product.currentUnitPrice}€
      </td>
      <td
        style={{
          textAlign: "center",
          fontSize: "smaller",
          backgroundColor: backgroundColor,
        }}
        className="item-line"
      >
        {product.currentQuantityOfUnit}
      </td>
      {isSelected ? (
        <td style={{ textAlign: "center" }}>
          <button
            className="icon-button"
            onClick={() => {
              removeSelectedProduct({
                product,
                setSelectedProducts,
                setProducts,
                products,
              });
            }}
          >
            <RiDeleteBin2Fill
              size={20}
              className="icon-hover"
              style={{ color: "red" }}
            ></RiDeleteBin2Fill>
          </button>
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  );
};
