import { Product } from "./types";
import { errorSwal } from "../src/swal";
export const distributeBudget = ({ products, budget }: any) => {
  let currentBudget = 0;
  let done = false;
  if (budget === 0) {
    errorSwal("Le budget est de 0€");
  }
  currentBudget = products.reduce(
    (prev: number, curr: Product) =>
      prev + curr.currentPackPrice * curr.quantity,
    0
  );

  if (currentBudget > budget) {
    errorSwal("Vous dépassez déjà le budget");
    return;
  }

  if (
    products.every((p: Product) => p.currentPackPrice > budget - currentBudget)
  ) {
    errorSwal("Le budget n'est pas assez élevé");
    return;
  }

  const sortedProducts = products.sort(
    (a: any, b: any) => a.currentPackPrice - b.currentPackPrice
  );
  while (!done) {
    let couldAddProduct = false;

    for (const p of sortedProducts) {
      if (currentBudget + p.currentPackPrice < budget) {
        p.quantity++;
        currentBudget += p.currentPackPrice;
        couldAddProduct = true;
      } else {
      }
    }
    if (!couldAddProduct) {
      done = true;
    }
  }

  return products;
};

export default distributeBudget;
